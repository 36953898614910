import { Box, Typography, MenuItem } from "@mui/material"
import Select from '@mui/material/Select';

import PaginationForward from '@assets/svg/PaginationForward.svg?react';
import PaginationBack from '@assets/svg/PaginationBack.svg?react';
import useBreakPoinst from "@hooks/useBreakPoinst";


const SInglePage = ({ lable = 1, isActive = true,onClick=()=>{} }) => {
    return (
        <Box sx={{
            width: '24px',
            height: '24px',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isActive ? 'rgba(91, 63, 255, 1)' : 'rgba(255, 255, 255, 1)',
            color: isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(91, 63, 255, 1)',
            mr: '10px',
            textAlign: 'center',
            cursor: 'pointer',
            boxShadow: ' 0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
        }} >
            <Box onClick={onClick} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }} >
                {lable}
            </Box>
        </Box>
    )
}

const Pagination = ({ onBack=()=>{},onForwared=()=>{}, onPageClicked=(p:any)=>{},currenPage=1,numberOfPages=5,perPage=5,onPerpageChange=(v:any)=>{}}) => {
    const {isPhone} = useBreakPoinst()
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' ,mt:3}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <PaginationForward 
                                onClick={onBack}

                style={{
                    width: '24px',
                    height: '24px',
                    cursor:'pointer',
                    transform:'rotate(180deg)'
                }} />

                <Box sx={{ display: 'flex' }} >
                    {Array.from({ length: numberOfPages }).map((x, i) => {
                        return <SInglePage lable={i+1} onClick={()=>onPageClicked(i+1)} isActive={currenPage==i+1} key={i} />
                    })}
                </Box>


                <PaginationForward
                onClick={onForwared}
                style={{
                    width: '24px',
                    height: '24px',
                    cursor:'pointer',
                    marginRight:'5px',
                    marginLeft:'5px'
                    
                }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Typography sx={{
                    color: 'rgba(142, 152, 161, 1)',
                    fontSize:isPhone?'10px': '14px',
                    fontWeight: 400,
                    mx: 1
                }} >
                    النتائج لكل صفحة
                </Typography> 
                <Select
                    sx={{
                        width: '65px',
                        height: '35px',
                        borderRadius: '8px',
                        color:'rgba(142, 152, 161, 1)',
                        

                    }}
                    value={perPage}
                    label=""
                    onChange={(v:any) => onPerpageChange(v.target.value)}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                </Select>

            </Box>

        </Box>
    )
}

export default Pagination