import CustomRadioButtonChannels from "@Common/CustomRadioButtonChannels"
import Input from "@Common/input"
import { useTranslation } from "react-i18next"
import { GathernChanelWrapper } from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import { Box, Typography } from "@mui/material"
export default function GathernChanel() {
	const { t } = useTranslation()

	return (
		<GathernChanelWrapper>
			<Box sx={{display:'flex'}} >
				<GathernIcon />
				<Typography sx={{
					color: '#435363',
					fontWeight: 700,
					lineHeight: '23px',
					fontSize: '14px',
					mx:1
				}} >
					Gatherin
				</Typography>
			</Box>
			<div className='containerFormInputs'>
				<div className='containerInput'>
					<label>{t("WEEKDAYS")}</label> 
					<Input
						suffixLabel={t('COMMON.RS')} 
						min={50}
						name='mid'
						placeholder={t("ENTERTONIGHTSPRICE")}
						registerOptions={{
							min: {
								value: 50,
								message: "اقل قيمه 50",
							},
							required: {
								value: false,
								message: '',
							},
						}}
					/>
				</div>
				<div className='containerInput'>
					<label>{t("THURSDAY")}</label>
					<Input
						suffixLabel={t('COMMON.RS')} 
						min={50}
						name='thu'
						placeholder={t("ENTERTONIGHTSPRICE")}
						registerOptions={{
							min: {
								value: 50,
								message: "اقل قيمه 50",
							},
							required: {
								value: false,
								message: '',
							},
						}}
					/>
				</div>
				<div className='containerInput'>
					<label>{t("FRIDAY")}</label>
					<Input
						suffixLabel={t('COMMON.RS')}  
						min={50}
						name='fri'
						placeholder={t("ENTERTONIGHTSPRICE")}
						registerOptions={{
							min: {
								value: 50,
								message: "اقل قيمه 50",
							},
							required: {
								value: false,
								message: '',
							},
						}}
					/>
				</div>
				<div className='containerInput'>
					<label>{t("SATURDAY")}</label>
					<Input
						suffixLabel={t('COMMON.RS')} 
						min={50}
						name='sat'
						placeholder={t("ENTERTONIGHTSPRICE")} 
						registerOptions={{
							min: {
								value: 50,
								message: "اقل قيمه 50",
							},
							required: {
								value: false,
								message: '',
							},
						}}
					/>
				</div>
			</div>
		</GathernChanelWrapper>
	)
}
