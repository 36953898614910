import { useTranslation } from "react-i18next"
import { DisplayNameOfDayWrapper } from "./styles"

const DaysName = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"]
export default function DisplayNameOfDay() {
	const { t } = useTranslation()

	return (
		<>
			{DaysName.map((item, index) => (
				<DisplayNameOfDayWrapper key={index}>
					<p>{t(item)}</p>
				</DisplayNameOfDayWrapper>
			))}
		</>
	)
}
