import styled, { css } from "styled-components"

export const ActionsButtonWrapper = styled.div<{ $isARLang: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
	.ContainernavigateButton {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		${(props) => !props.$isARLang &&
			css`
				flex-direction: row-reverse;
			`}
		button {
			padding: 12px 16px;
			border: 1px solid #eaedf1;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #5b3fff;
			font-size: 20px;
			cursor: pointer;

			&:first-child {
				border-radius: 0 8px 8px 0;
			}
			&:last-child {
				border-radius: 8px 0 0 8px;
			}
		}
	}
	.todayButton {
		padding: 14px 16px;
		border: 1px solid #39a2db;
		background-color: #39a2db;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		color: #fff;
		cursor: pointer;
		text-transform: capitalize;
	}
`
