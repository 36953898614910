import { GuestMangmentHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"

export default function GuestMangmentHeader() {
	const { t } = useTranslation()
	return (
		<GuestMangmentHeaderWrapper>
			<div>
				<p>{t("GUESTMANAGEMENT")}</p>
				<span>مساحة تعينك على إلقاء النظر ، إدارة ضيوفك</span>
			</div>
			<div></div>
		</GuestMangmentHeaderWrapper>
	)
}
