import styled from "styled-components"

export const SectionControlsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
	> div {
		&:first-child {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 12px;
		}
		&:last-child {
			color: #5b3fff;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 4px;
		}
	}
`
