import { MutableRefObject } from "react"
import { FiltersWrapper } from "./styles"
import { Grid, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"
import FullCalendar from "@fullcalendar/react"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
	controleAdvancedSettings,
	controleBookingDetails,
	gitBookingInfo,
	isMonthlyView,
	reinitializeCalendar,
} from "@store/index"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
}
export default function Filters({ calendarRef }: IProps) {
	const [controleAdvancedSettingsValue, controleAdvancedSettingsSetValue] =
		useRecoilState(controleAdvancedSettings)
	const [reinitializeCalendarValue, reinitializeCalendarSetValue] =
		useRecoilState(reinitializeCalendar)
	const controleBookingDetailsState = useSetRecoilState(controleBookingDetails)
	const gitBookingInfoState = useSetRecoilState(gitBookingInfo)

	const [isMonthly, setIsMonthly] = useRecoilState(isMonthlyView)
	const { t } = useTranslation()
	const typeOptions = [
		{
			label: t("COMMON.MONTHLY"),
			value: 0,
			onClick: () => setIsMonthly("0"),
		},
		{
			label: t("COMMON.WEEKLY"),
			value: 1,
			onClick: () => setIsMonthly("1"),
		},
	]
	const handleChange = (event: SelectChangeEvent) => {
		setIsMonthly(event.target.value.toString() as "1" | "0")
	}

	return (
		<FiltersWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<div className='containerInfo'>
						<p>التقويم</p>
						<p>مساحة تعينك على إلقاء النظر علي حجوزاتك</p>
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div className='containerControls'>
						{/* <div className='containerSelectOption'>
							<Select
								sx={{
									borderRadius: 5,
									color: "rgba(38, 27, 106, 1)",
									fontWeight: 700,
									".MuiOutlinedInput-notchedOutline": {
										borderColor: "#5B3FFF",
										borderWidth: 0,
										// backgroundColor:'white',
									},
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#5B3FFF",
										borderWidth: 0,
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										borderColor: "#5B3FFF",
										borderWidth: 0,
									},
									".MuiSvgIcon-root ": {
										fill: "rgba(38, 27, 106, 1) !important",
									},
								}}
								value={isMonthly}
								size={"small"}
								onChange={handleChange}
							>
								{typeOptions?.map((i) => {
									return (
										<MenuItem
											value={i.value}
											key={i.value}
											onClick={() => i.onClick()}
										>
											{i.label}
										</MenuItem>
									)
								})}
							</Select>
						</div> */}
						<div
							className={`${!controleAdvancedSettingsValue ? "containerButton" : "active"}`}
							onClick={() => {
								controleAdvancedSettingsSetValue(!controleAdvancedSettingsValue)
								controleBookingDetailsState(false)
								reinitializeCalendarSetValue(reinitializeCalendarValue + 1)
								gitBookingInfoState(undefined)
							}}
						>
							<button>الإعدادات المتقدمة</button>
						</div>
					</div>
				</Grid>
			</Grid>
		</FiltersWrapper>
	)
}
