export const getMissingRange = (arr:any)=>{

    let temp:any = []
    arr =  arr?.sort(function (a:any, b:any) {
        return a - b;
    })
    for (var i = arr[0]; i <= arr[arr.length-1]; i++) {
        temp.push(Number(i));
    }
    return temp

}