import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelectCheckmarks({isError=false,placeholder='',label='',data=[],values=[],setValues}:any) {
  const { t,i18n:{language} } = useTranslation(); 
  const isRtl = language  === 'ar'
  const handleChange = (event: any) => {
    let prevAllSelected = values?.includes(t('COMMON.All'))
    const {
      target: { value },
    } = event;
    let newValue =   typeof value === 'string' ? value.split(',') : value
    // if( newValue?.includes(t('COMMON.All')) ){
    //   newValue = [t('COMMON.All')]
    // }
   
    let newSelected = ''
    if(prevAllSelected){
      let nn = newValue?.filter((x:any)=>!values?.includes(x))
      if( nn?.length >0 ){
        newSelected = nn[0]
      }


    }
    setValues(newSelected!==''?[newSelected]:newValue);
  };

  return (
    <div>
      <FormControl sx={{ maxWidth:'105%', width:'105%',borderRadius:'100px',mb:1.5}}>
       <Typography sx={{
        fontSize:'20px',
        fontWeight:500,
        lineHeight:'30px',
        color:'#261B6A',
        mb:0.8
       }} >
       {label}
       </Typography>
       <Typography sx={{
        fontSize:'16px',
        fontWeight:400,
        lineHeight:'30px',
        color:'#435363',
      position:'absolute',
      top:'45px',
      [isRtl?"right":"left"]:25,
      opacity:values?.length==0?1:0
       }} >
       {placeholder}
       </Typography>
        <Select
          multiple
          error={isError}
          value={values} 
          onChange={handleChange}
          input={  <OutlinedInput 
            sx={{borderRadius:'24px',height:'45px'}}  />}
          renderValue={(selected) =>selected?.includes(t('COMMON.All'))? selected?.filter((x:any)=>x==t('COMMON.All')).join(', '):selected.join(', ')}
          MenuProps={MenuProps}
        >
            
          {data.map((name:any) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={values?.includes(t('COMMON.All'))?true:values.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}