import CustomTable from "@Common/CustomTable"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import CardsInfo from "./cardsInfo"
import { ReservationManagementWrapper } from "./styles"
import ReservationMangmentHeader from "./reservationMangmentHeader"
import { useReservations } from "@services"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
export default function ReservationManagement() {
	const channals = {
		1: <AirbnbIcon />,
		2: <GathernIcon />,
		3: <BookingIcon />,
		4: <ChannelAgoda />,
		5: "Other",
		6: <MsoolIcon />,
	}
	const channalsTitle = {
		1: "Airbnb",
		2: "Gathern",
		3: "Booking",
		4: "ChannelA",
		5: "Other",
		6: "Msool",
	}
	const [perPage, onPerpageChange] = useState("20")
	const [activePage, setActivePage] = useState(1)
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const { ReservationsQuery } = useReservations()
	const { data: Reservations } = ReservationsQuery({
		pageNumber: activePage.toString(),
		pageSize: perPage,
	})

	const columns = [
		{
			field: "reservationId",
			headerName: "رقم الحجز",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.reservationId}
				</p>
			),
		},
		{
			field: "checkIn",
			headerName: "موعد دخول",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.checkIn}
				</p>
			),
		},
		{
			field: "checkOut",
			headerName: "موعد خروج",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.checkOut}
				</p>
			),
		},
		{
			field: "propertyName",
			headerName: "اسم العقار",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.propertyName}
				</p>
			),
		},
		{
			field: "channelName",
			headerName: "المنصة",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{channals[params.row.platFormSRC as 1 | 2 | 3 | 4 | 5 | 6]}
					{" "}
					{channalsTitle[params.row.platFormSRC as 1 | 2 | 3 | 4 | 5 | 6]}
				</p>
			),
		},
		{
			field: "bookingStatus",
			headerName: "حالة الحجز",
			flex: 1,
			renderCell: (params: any) => (
				<div className='containerStatus'>
					<p
						className={`"columnsContent" ${
							params.row.bookingStatus === "مقبول"
								? "accept"
								: params.row.bookingStatus === "مرفوض"
									? "reject"
									: params.row.bookingStatus === "معلق"
										? "pendding"
										: "known"
						}`}
						style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
					>
						<span></span>
						{params.row.bookingStatus}
					</p>
				</div>
			),
		},
		{
			field: "guestName",
			headerName: "اسم الضيف",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.guestName}
				</p>
			),
		},
		{
			field: "totalPrice",
			headerName: "اجمالي السعر",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					<span>{params.row.totalPrice}</span> <span>ريال سعودي</span>
				</p>
			),
		},
	]

	const rows = useMemo(() => {
		const data = Reservations?.data?.result?.reservationDTOs?.map((item) => {
			return {
				id: item.id,
				reservationId: item.reservationId,
				checkIn: item.checkIn,
				checkOut: item.checkOut,
				propertyName: item.propertyName,
				channelName: item.channelName,
				bookingStatus: item.bookingStatus,
				guestName: item.guestName,
				totalPrice: item.totalPrice,
				platFormSRC: item.platFormSRC,
			}
		})
		return data
	}, [Reservations])

	return (
		<ReservationManagementWrapper>
			<ReservationMangmentHeader />
			<CardsInfo />
			<div className='containerTable'>
				<CustomTable
					columns={columns}
					rows={rows ?? []}
					HeaderTheTable={t("RESERVATIONLIST")}
					totalPages={
						Reservations?.data?.totalRecords
							? Math.ceil(Reservations?.data?.totalRecords / Number(perPage))
							: 1
					}
					setActivePage={setActivePage}
					activePage={activePage}
					onPerpageChange={onPerpageChange}
					perPage={perPage}
					listPerPagesNumber={[
						{
							label: "20",
							value: "20",
						},
						{
							label: "50",
							value: "50",
						},
						{
							label: "100",
							value: "100",
						},
					]}
				/>
			</div>
		</ReservationManagementWrapper>
	)
}
