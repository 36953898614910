import AirbnbIcon from "@assets/svg/airbnb-icon.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import { EventWrapper } from "./styles"
import dayjs from "dayjs"
import { useSetRecoilState } from "recoil"
import {
	controleAdvancedSettings,
	controleBookingDetails,
	gitBookingInfo,
} from "@store/index"
import { IGitBookingInfoState } from "@store/calenderPage/gitBookingInfo"
interface IProps {
	IslanguageAr: boolean
	rowNumber: number
	columnStartNumber: number
	columnEndNumber: number
	isContinueAfterWeek: boolean
	eventEndDateIsFirstDayOfWeek: boolean
	data: any
}
export const RenderEventContent = ({
	IslanguageAr,
	rowNumber,
	columnStartNumber,
	columnEndNumber,
	eventEndDateIsFirstDayOfWeek,
	isContinueAfterWeek,
	data,
}: IProps) => {
	const gitBookingInfoState = useSetRecoilState(gitBookingInfo)
	const controleBookingDetailsState = useSetRecoilState(controleBookingDetails)
	const controleAdvancedSettingsSetValue = useSetRecoilState(
		controleAdvancedSettings
	)
	return (
		<EventWrapper
			$IslanguageAr={IslanguageAr}
			$rowNumber={rowNumber}
			$columnStartNumber={columnStartNumber}
			$columnEndNumber={columnEndNumber}
			$isContinueAfterWeek={isContinueAfterWeek}
			$eventEndDateIsFirstDayOfWeek={eventEndDateIsFirstDayOfWeek}
			$ReservationFrom={data?.eventFlag as "airbnb" | "gathern" | "booking"}
			$continueToEnd={data.continueToEnd}
			$continueToStart={data.continueToStart}
			$allWeek={data.allWeek}
			onClick={() => {
				controleBookingDetailsState(true)
				controleAdvancedSettingsSetValue(false)
				gitBookingInfoState(data as IGitBookingInfoState)
			}}
		>
			<div className='WrappContainerEvent'>
				<div className='containerEvent'>
					<div className='containerIcons'>
						{data?.eventFlag === "airbnb" && <AirbnbIcon />}
						{data?.eventFlag === "gathern" && <GathernIcon />}
						{data?.eventFlag === "booking" && <BookingIcon />}
					</div>
					<div className='containerReservationInfo'>
						<span>
							{data?.bookingInfo?.guestName ?? data?.bookingInfo?.user_name} -
							{dayjs(data?.bookingInfo?.checkOut).diff(
								dayjs(data?.bookingInfo?.checkIn),
								"days"
							) === 1 ? (
								"ليلة"
							) : (
								<span>{`${dayjs(data?.bookingInfo?.checkOut).diff(dayjs(data?.bookingInfo?.checkIn), "days")} ليالي`}</span>
							)}
						</span>
						<span>
							{data?.bookingInfo?.totalPrice ?? data?.bookingInfo?.price} ريال
						</span>
					</div>
				</div>
			</div>
		</EventWrapper>
	)
}
