import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IClassfication {
	guestName: string
	classficationId: string
}
export const classfication = atom<IClassfication | undefined>({
	key: ATOMS_KEYS.CLASSFICATION,
	default: {
		guestName: "",
		classficationId: "",
	},
})
