import CustomButton from "@Common/CustomButton"
import { PopupConfirmationWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { useGuestManagement } from "@services"
import { Dispatch, SetStateAction } from "react"
import { useRecoilState } from "recoil"
import { classfication } from "@store/index"

interface IProps {
	setClassficationPopup: Dispatch<SetStateAction<boolean>>
}
export default function PopupConfirmation({ setClassficationPopup }: IProps) {
	const [classficationValue, setClassficationState] =
		useRecoilState(classfication)

	const { t } = useTranslation()
	const mesages = {
		"157": `${t("AREYOUSUREYOUWANTTOCLASSIFYTHISGUESTASSTANDARD")}`,
		"158": `${t("AREYOUSUREYOUWANTTOCLASSIFYTHISGUESTASVIP")}`,
		"159": `${t("AREYOUSUREYOUWANTTOBLACKLISTTHISGUEST")}`,
	}
	const { updateGuestsMutate, GuestManagementClassificationQuery } =
		useGuestManagement()
	const { data: classificationQuery } = GuestManagementClassificationQuery()

	const { mutateAsync: updateGuest } = updateGuestsMutate()

	const onSubmit = async () => {
		if (!classficationValue?.classficationId && !classficationValue?.guestName)
			return
		const CurrantData = classificationQuery?.data.result.filter(
			(item) =>
				item.lookupId.toString() ===
				classficationValue?.classficationId.toString()
		)
		const res = await updateGuest({
			classificationString: CurrantData?.[0].name!,
			guestName: classficationValue?.guestName,
		})
		if (res.status === 200) {
			window.location.reload()
		}
	}

	return (
		<PopupConfirmationWrapper>
			<div className='PopupConfirmationCard'>
				<div className='header'>
					<p>تعديل التصنيف</p>
				</div>
				<div className='body'>
					<p>
						{
							mesages[
								classficationValue?.classficationId as "159" | "158" | "157"
							]
						}
					</p>
				</div>
				<div className='footer'>
					<CustomButton
						label={t("CANCEL")}
						onClick={() => {
							setClassficationState(undefined)
							setClassficationPopup(false)
						}}
					/>
					<CustomButton withBG label={t("SAVE")} onClick={() => onSubmit()} />
				</div>
			</div>
		</PopupConfirmationWrapper>
	)
}
