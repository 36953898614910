import { useRecoilValue } from "recoil"
import ActionsButton from "./ActionsButton"
import SwitchTheChannel from "./SwitchTheChannel"
import { SectionControlsWrapper } from "./styles"
import { getDaysOfWeek } from "@store/index"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import "dayjs/locale/en"
import "dayjs/locale/ar"
import { Dispatch, SetStateAction } from "react"

interface IProps {
	channel: "1" | "2" | "3"
	setChannel: Dispatch<SetStateAction<"1" | "2" | "3">>
}
export default function SectionControls({ channel, setChannel }: IProps) {
	const getDaysOfWeekValue = useRecoilValue(getDaysOfWeek)
	const {
		i18n: { language },
	} = useTranslation()

	return (
		<SectionControlsWrapper>
			<div>
				<ActionsButton />
				{/* <SwitchTheChannel channel={channel} setChannel={setChannel} /> */}
			</div>
			<div>
				<span>
					{dayjs(getDaysOfWeekValue[0]).locale(language).format("MMMM")}
				</span>
				<span>
					{dayjs(getDaysOfWeekValue[0]).locale(language).format("YYYY")}
				</span>
			</div>
		</SectionControlsWrapper>
	)
}
