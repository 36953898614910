import styled from "styled-components"

export const MultiChannelWrapper = styled.div`
	margin-top: 8px;
	.containerFormInputs {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	> :nth-child(2) {
		margin-top: 8px;
		.containerInputs {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			gap: 16px;
			> div {
				width: 100%;
			}
		}
	}
`
