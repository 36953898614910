import styled from "styled-components"

export const SearchInputFormWrapper = styled.div`
	background-color: #f9f9f9;
	border-radius: 8px;
	overflow: hidden;
	form {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		input {
			background-color: #f9f9f9;
			border: 1px solid #f9f9f9;
			border-radius: 0;
			padding-left: 0;
		}
	}
`
