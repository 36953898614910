import styled, { css } from "styled-components"

export const EventWrapper = styled.div<{
	$IslanguageAr: boolean
	$rowNumber: number
	$columnStartNumber: number
	$columnEndNumber: number
	$isContinueAfterWeek: boolean
	$eventEndDateIsFirstDayOfWeek: boolean
	$continueToEnd: boolean
	$continueToStart: boolean
	$allWeek: boolean
	$ReservationFrom: "airbnb" | "gathern" | "booking"
}>`
	background-color: transparent;
	position: absolute;
	grid-row: ${(props) => props.$rowNumber};
	grid-column-start: ${(props) => props.$columnStartNumber};
	grid-column-end: ${(props) => props.$columnEndNumber};
	display: inline-block;
	/* width: ${(props) => (props.$continueToStart ? "110%" : "100%")}; */
	width: 100%;
	height: 90px;
	/* margin-inline-start: ${(props) =>
		(props.$continueToEnd && !props.$allWeek) ||
		(!props.$continueToEnd && !props.$continueToStart && !props.$allWeek) ||
		!props.$allWeek
			? "20px"
			: "0"}; */
	display: ${(props) => (props.$eventEndDateIsFirstDayOfWeek ? "none" : "")};

	/* ------------------------------- custom CSS ------------------------------- */
	${(props) =>
		props.$allWeek &&
		css`
			> div {
				> div {
					border-radius: 0 !important;
				}
			}
		`}
	${(props) =>
		props.$continueToEnd &&
		props.$IslanguageAr &&
		css`
			/* padding-right: 15px; */
			> div {
				> div {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}
		`}
	${(props) =>
		props.$continueToEnd &&
		!props.$IslanguageAr &&
		css`
			padding-right: 15px;
			> div {
				> div {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		`}
	${(props) =>
		props.$continueToStart &&
		props.$IslanguageAr &&
		css`
			/* padding-right: 15px; */
			> div {
				> div {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		`}
	${(props) =>
		props.$continueToStart &&
		!props.$IslanguageAr &&
		css`
			padding-right: 15px;
			> div {
				> div {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}
		`}

	/* ${(props) =>
		props.$isContinueAfterWeek &&
		props.$IslanguageAr &&
		css`
			padding-right: 15px;
			> div {
				> div {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}
		`}
	${(props) =>
		props.$isContinueAfterWeek &&
		!props.$IslanguageAr &&
		css`
			padding-left: 15px;
			> div {
				> div {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		`} */

	/* ------------------------------- custom CSS ------------------------------- */




	.WrappContainerEvent {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		.containerEvent {
			width: 100%;
			margin: 0;
			background-color: #fff;
			border-radius: 20px;
			box-shadow: 0px 0px 25px 0px #5b3fff33;
			padding: 2px 5px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 7px;
			margin-bottom: 6px;
			.containerIcons {
				display: flex;
				justify-content: center;
				align-items: center;
				border-left: 1px solid
					${(props) =>
						props.$ReservationFrom === "airbnb"
							? "#F3A8AA"
							: props.$ReservationFrom === "booking"
								? "#39639D"
								: props.$ReservationFrom === "gathern"
									? "#B3A0E2"
									: "#000"};
				${(props) =>
					!props.$IslanguageAr &&
					css`
						border-left: none;
						border-right: 1px solid;
					`}
				padding-inline-end: 5px;
				width: 24px;
				height: 24px;
				svg {
					width: 20px;
					height: 20px;
				}
			}
			.containerReservationInfo {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: column;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: #435363;
					font-size: 12px;
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 5px;
				}
			}
		}
	}
`
