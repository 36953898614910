import {
	HTMLInputTypeAttribute,
	InputHTMLAttributes,
	useEffect,
	useState,
} from "react"
import { useFormContext, RegisterOptions } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { InputWrapper } from "./styles"
import { IoIosSearch } from 'react-icons/io';
import { Box } from '@mui/material';
import { useTranslation } from "react-i18next"

interface IProps
	extends Pick<
		InputHTMLAttributes<HTMLInputElement>,
		"maxLength" | "disabled" | "min" | "max" | "step"
	> {
	name: string
	registerOptions?: RegisterOptions
	type?: Exclude<HTMLInputTypeAttribute, "range" | "reset" | "submit">
	placeholder?: string
	label?: string
	readonly?: boolean
	labelVisibility?: boolean
	className?: string
	defaultValue?: string | number
	withOutErrorMessage?: boolean
	direction?: "rtl" | "ltr"
	inputmode?: "numeric" | "decimal"
	onFocus?: any
	onBlur?: any
	suffixLabel?: string

}

function Input({
	name,
	registerOptions,
	type = "text",
	placeholder,
	label,
	readonly,
	labelVisibility = true,
	className,
	disabled,
	min,
	max,
	step,
	defaultValue,
	withOutErrorMessage = false,
	direction,
	inputmode,
	onFocus,
	onBlur,
	suffixLabel = ''
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { register, formState } = useFormContext()
	const { errors } = formState
	const { t,i18n:{language} } = useTranslation(); 
	const isRtl = language  === 'ar'

	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])
	return (
		<InputWrapper
			$islabelVisible={labelVisibility}
			className={className}
			$isError={errors[name] !== undefined ?? false}
			disabled={disabled}
			$direction={direction}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
					</p>
				)}
				<div className='inputcontainer'>
					{suffixLabel?.length>0 && <Box sx={{
						color: '#5B3FFF', fontWeight: 500, fontSize: '11.8px',
						position: 'absolute', [isRtl?"left":"right"]: 10, top: 10,
						zIndex:1000
					}} >{suffixLabel}</Box>}
					<input
						autoComplete='off'
						type={type}
						placeholder={placeholder}
						{...register(name, registerOptions)}
						readOnly={readonly}
						disabled={disabled}
						min={min}
						max={max}
						step={step}
						defaultValue={defaultValue}
						inputMode={inputmode}
						onFocus={onFocus}
						onBlur={onBlur}

					/>
					{type === "search" && (
						<div className='search'>
							<IoIosSearch />
						</div>
					)}
				</div>
			</label>
			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</InputWrapper>
	)
}

export default Input
