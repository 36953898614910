import { useTranslation } from "react-i18next"
import { PopupReservationsListWrapper } from "./styles"
import { IoClose } from "react-icons/io5"
import { LuCalendarDays } from "react-icons/lu"
import { GustReservation } from "src/services/useGuestManagement/types"
import { Dispatch, SetStateAction } from "react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"

interface IProps {
	reservationsList: GustReservation[]
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
}
export default function PopupReservationsList({
	reservationsList,
	setIsPopupOpen,
}: IProps) {
	const { t } = useTranslation()
	const channals = {
		1: <AirbnbIcon />,
		2: <GathernIcon />,
		3: <BookingIcon />,
		4: <ChannelAgoda />,
		5: "Other",
		6: <MsoolIcon />,
	}

	return (
		<PopupReservationsListWrapper>
			<div className='containerHeader'>
				<p>{t("GUESTRESERVATIONS")}</p>
				<div onClick={() => setIsPopupOpen(false)}>
					<IoClose />
				</div>
			</div>

			<div className='containerAllData'>
				{reservationsList.map((item) => {
					return (
						<div className='containerCard' key={item.id}>
							<div>
								<div>
									{channals[item?.platFormSRC as 1 | 2 | 3 | 4 | 5 | 6]}
								</div>
								<div>
									<p>{item.propertyName}</p>
								</div>
							</div>
							<div>
								{/* <div></div> */}
								<div>
									<p>
										<span>
											<LuCalendarDays />
										</span>
										<span>{t("BOOKINGDATES")}</span>
									</p>
									<p>
										<span>{t("CALENDAR.CHECK_IN")}</span>
										<span>{item.checkIn}</span>
									</p>
									<p>
										<span>{t("CALENDAR.CHECK_OUT")}</span>
										<span>{item.checkIn}</span>
									</p>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</PopupReservationsListWrapper>
	)
}
