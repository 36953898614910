import styled from "styled-components"

export const DisplayNameOfDayWrapper = styled.div`
	background-color: #f9f9f9;
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-inline: 8px;
	p {
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #435363;
		font-size: 18px;
		font-weight: 400;
		line-height: 21.6px;
	}
`
