import styled from "styled-components"

export const CalenderViewWrapper = styled.div`
	display: grid;
	grid-template-columns: 150px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 54px;
	gap: 8px;
	position: relative;

	.propertyName {
		padding: 8px;
		height: 90px;
		border-radius: 8px;
		background-color: #f9f9f9;
		display: grid;
		grid-template-rows: 30px 1fr;
		grid-template-columns: 100%;
		.platforms {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			span {
				border: 1px solid #fff;
				width: 20px;
				height: 20px;
				display: inline-block;
				border-radius: 50px;
				margin-left: -10px;
				background-color: #5b3fff;
				&:last-child {
					margin-left: 0;
				}
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		.bookingInfo {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			flex-direction: column;
			p {
				margin: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				&:first-child {
					color: #435363;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
				}
				&:last-child {
					color: #6b7280;
					font-size: 12px;
					font-weight: 300;
					line-height: 14.4px;
				}
			}
		}
	}
	.propertyInfo {
		background-color: #f9f9f9;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		> div {
			padding: 5px;
			&:first-child {
				font-size: 12px;
				font-weight: 500;
				line-height: 14.52px;
				color: #435363;
			}
			&:last-child {
				font-size: 12px;
				font-weight: 400;
				line-height: 12px;
				color: #435363;
			}
		}
	}
	.currantDay {
		border: 4px solid #39a2db;
	}
`
