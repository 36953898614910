import { FC, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';

const LoadingWrapper: FC<{ children: ReactNode; loading: boolean }> = ({ children, loading = false }) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className={'relative'}>
      <div className={'pointer-events-auto'}>{children}</div>

      <div className={'absolute flex items-center justify-center gap-2 inset-0 bg-white/50'}>
        <CircularProgress />
      </div>
    </div>
  );
};
export default LoadingWrapper;
