import { useEffect, useMemo } from "react"
import { ActionsButtonWrapper } from "./styles"
import dayjs from "dayjs"
import { useRecoilState } from "recoil"
import { getDaysOfWeek } from "@store/index"
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md"
import { useTranslation } from "react-i18next"

export default function ActionsButton() {
	/* ------------------------------ State Manager ----------------------------- */
	const [getDaysOfWeekValue, setDaysOfWeek] = useRecoilState(getDaysOfWeek)
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		i18n: { language },
	} = useTranslation()
	/* -------------------------------- Functions ------------------------------- */
	const getPrevWeek = () => {
		const startOf = getDaysOfWeekValue[0]
		const displayDaysOfWeek = []
		for (let i = 1; i <= 7; i++) {
			displayDaysOfWeek.push(
				dayjs(startOf).subtract(i, "d").format("YYYY-MM-DD")
			)
		}
		setDaysOfWeek(displayDaysOfWeek.reverse())
	}

	const getNextWeek = () => {
		const startOf = getDaysOfWeekValue[6]
		const displayDaysOfWeek = []
		for (let i = 1; i <= 7; i++) {
			displayDaysOfWeek.push(dayjs(startOf).add(i, "day").format("YYYY-MM-DD"))
		}
		setDaysOfWeek(displayDaysOfWeek)
	}

	const getCurrantWeek = () => {
		const displayDaysOfWeek = []
		for (let i = 0; i < 7; i++) {
			displayDaysOfWeek.push(
				dayjs(new Date()).day(-1).add(i, "day").format("YYYY-MM-DD")
			)
		}
		return setDaysOfWeek(displayDaysOfWeek)
	}
	/* ---------------------------------- Hooks --------------------------------- */
	useMemo(() => {
		const displayDaysOfWeek = []
		for (let i = 0; i < 7; i++) {
			displayDaysOfWeek.push(
				dayjs(new Date()).day(-1).add(i, "day").format("YYYY-MM-DD")
			)
		}
		return setDaysOfWeek(displayDaysOfWeek)
	}, [])

	return (
		<ActionsButtonWrapper $isARLang={language === "ar"}>
			<div className='ContainernavigateButton'>
				<button onClick={() => getNextWeek()}>
					<MdOutlineNavigateNext />
				</button>
				<button onClick={() => getPrevWeek()}>
					<MdOutlineNavigateBefore />
				</button>
			</div>
			<button className='todayButton' onClick={() => getCurrantWeek()}>
				today
			</button>
		</ActionsButtonWrapper>
	)
}
