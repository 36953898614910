// @ts-nocheck

import { CalenderViewWrapper } from "./styles"
import SearchInputForm from "../components/SearchInputForm"
import DisplayNameOfDay from "../components/DisplayNameOfDay"
import Properties from "../components/Properties"
import { useCalendar, useProperty } from "@services"
import { useEffect, useMemo } from "react"
import { Events } from "./types"
export interface IListOfProperties {
	propertyId: number
	propertyName: string
	propertyIsDeteled: boolean
	propertyCreateDate: string
	gathrenUnitId?: string
	airbnbUnitId?: string
	isAutoAccess: boolean
	status: boolean
	propertyLocation: string
	templateId: any
	hasListingProfile: boolean
	listingProfileId: any
}
interface IProps {
	channel: "1" | "2" | "3"
}
export default function CalenderView({ channel }: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { getManagementListQuery, gathrenListingQuery, getAirbnbListingQuery } =
		useProperty()
	const { data: managementList } = getManagementListQuery()
	const { airbnbListing, gathernListing } = useCalendar()
	const { data: gathrenListing } = gathrenListingQuery()
	const { data: ListingAirbnb } = getAirbnbListingQuery(
		localStorage.getItem("channexChannelId") as string
	)
	const { data: airbnbListingData } = airbnbListing()
	const { data: gathernListingData } = gathernListing()
	const listOfProperties: IListOfProperties[] = useMemo(() => {
		const filteData = managementList?.filter(
			(item: any) => item.airbnbUnitId !== null || item.gathrenUnitId !== null
		)
		const filterByAirbnbChannel = filteData?.filter(
			(item: any) => item.airbnbUnitId?.length > 0
		)
		const filterByGathrenChannel = filteData?.filter(
			(item: any) => item.gathrenUnitId?.length > 0
		)
		const data =
			channel?.toString() === "1"
				? filterByAirbnbChannel
				: filterByGathrenChannel
		return filteData
	}, [managementList, channel])

	const listOfPropertiesGathern: Events = useMemo(() => {
		const filteData = gathernListingData?.map((item: any) => {
			return item.dates
		})
		const mergedArray = filteData?.reduce(
			(acc: any, curr: any) => acc.concat(curr),
			[]
		)
		const dataFiltered = mergedArray?.filter(
			(item: any) => item.checkIn && item.checkOut
		)
		const newArray = dataFiltered?.map((item: any) => {
			return {
				bookingInfo: item,
				eventFlag: "gathern",
			}
		})
		return newArray
	}, [gathernListingData])

	const priceList = useMemo(()=>{
		let list:any = {} 
		airbnbListingData?.map((x:any)=>{
			let updated = {...list[x.propertyId]}
			x.calendarResponse?.map((xx:any)=>{
				updated[xx.date]={
					airbnbPrice:xx?.daily_price
				}
			})
			list[x.propertyId]= updated 
			// let prev = list[x.propertyId] [] ?{...list[x.propertyId]}:{}

		})
		 gathernListingData?.map((x:any)=>{
			let updated ={...list[x.propertyId]}
			x?.dates?.map((xx:any)=>{
				let prevPrice=updated[xx.date]
				updated[xx.date]={
					gatherinPrice:xx?.price,
					...prevPrice
				}
			})
			list[x.propertyId]= updated 

		 })	 
		return list

	},[gathernListingData,airbnbListingData])



	const listOfPropertiesData: Events = useMemo(() => {
		const filteData = airbnbListingData?.flatMap((item: any) => { 
			return item.calendarResponse
		})
		const data = filteData?.filter((item: any) => item.bookingInfo !== null)
		const dataWithFlag = data?.map((item: any) => {
			return { ...item, eventFlag: "airbnb" }
		})
		const airbnb = channel.toString() === "1" ? dataWithFlag : []
		const gathern = channel.toString() === "2" ? listOfPropertiesGathern : []
		// return airbnb?.concat(gathern ?? [])
		return (dataWithFlag ?? [])?.concat(listOfPropertiesGathern ?? [])
	}, [airbnbListingData, channel,listOfPropertiesGathern])

	// const ImagesList = useMemo(() => {
	// 	let listItem: any[] = []
	// 	ListingAirbnb?.response?.data?.listing_id_dictionary?.values?.map(
	// 		(cc: any) => {
	// 			if (listItem[cc.title]) {
	// 				listItem[cc.title] = { ...listItem[cc.title], airbnb: cc?.image }
	// 			} else {
	// 				listItem[cc.title] = {
	// 					airbnb: cc?.image,
	// 					gatherin: "",
	// 				}
	// 			}
	// 		}
	// 	)
	// 	gathrenListing?.chalets
	// 		?.map((c: any) => {
	// 			return c.units?.map((u: any) => ({
	// 				...u,
	// 				location: c.location,
	// 				chaletId: c.id,
	// 			}))
	// 		})
	// 		?.flat()
	// 		?.map((x: any) => {
	// 			if (listItem[x.title]) {
	// 				listItem[x.title] = {
	// 					...listItem[x.title],
	// 					gatherin: x?.image,
	// 				}
	// 			} else {
	// 				listItem[x.title] = {
	// 					gatherin: x?.image, 
	// 					airbnb: "",
	// 				}
	// 			}
	// 		}) ?? []

	// 	return listItem
	// }, [gathrenListing, ListingAirbnb])
	return (
		<CalenderViewWrapper>
			<SearchInputForm />  
			<DisplayNameOfDay />
			<Properties 
				listOfProperties={listOfProperties}
				listOfPropertiesData={listOfPropertiesData}
				priceList={priceList} 
			/>
		</CalenderViewWrapper>
	)
}
