import styled from "styled-components"

export const BookingDetailsWrapper = styled.div`
	> div {
		&:nth-child(1) {
			border-bottom: 1px solid #f0f1f2;
			padding-bottom: 8px;
			> :nth-child(1) {
				margin-bottom: 8px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 8px;
				span {
					&:nth-child(1) {
						width: 40px;
						height: 40px;
						svg {
							width: 100%;
							height: 100%;
						}
					}
					&:last-child {
						color: #261b6a;
						font-size: 14px;
						font-weight: 500;
						line-height: 17.07px;
					}
				}
			}
			> :nth-child(2) {
				background-color: #fff2d2;
				border-radius: 50px;
				padding: 8px 12px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 8px;
				width: fit-content;
				:first-child {
					background-color: #fdbf21;
					border-radius: 50px;
					width: 10px;
					height: 10px;
				}
				:last-child {
					color: #fdbf21;
				}
			}
		}
		&:nth-child(2) {
			border-bottom: 1px solid #f0f1f2;
			margin-top: 30px;
			padding-bottom: 8px;
			margin-bottom: 24px;
			:nth-child(1) {
				p {
					margin: 0;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 8px;
					span {
						&:last-child {
							color: #435363;
							font-size: 14px;
							font-weight: 500;
							line-height: 16.8px;
						}
					}
				}
			}
			:nth-child(2) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				p {
					margin: 0;
					gap: 8px;
					font-size: 14px;
					font-weight: 700;
					line-height: 16.8px;
					&:first-child {
						color: #5b3fff;
					}
					&:last-child {
						color: #130080;
					}
				}
			}
		}
		&:nth-child(3) {
			> :first-child {
				display: flex;
				justify-content: space-between;
				align-items: center;
				span {
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					&:first-child {
						color: #435363;
					}
					&:last-child {
						color: #130080;
					}
				}
			}
			> :nth-child(2) {
				margin: 16px 0;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				color: #435363;
			}
			> :nth-child(3) {
				div {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 16px;
					span {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.4px;
						&:nth-child(1) {
							color: #8e98a1;
						}
						&:nth-child(2) {
							font-size: 14px;
							color: #130080;
						}
					}
				}
			}
		}
	}
`
