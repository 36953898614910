import styled from "styled-components"

export const GathernChanelWrapper = styled.div`
	margin-top: 16px;
	.containerFormInputs {
		display: flex;
		gap: 8px;
		flex-direction: column;
		padding-top: 8px;
		margin-top: 4px;
		.containerInput {
			display: flex;
			justify-content: space-between;
			align-items: center;
			> label {
				font-size: 15px;
				font-weight: 400;
				line-height: 21.6px;
				color: #435363;
				width: 30%;
			}
		}
	}
`
