import dayjs from "dayjs"
import { Fragment, useMemo } from "react"
import { useRecoilValue } from "recoil"
import { getDaysOfWeek } from "@store/index"
import isBetween from "dayjs/plugin/isBetween"
import { useTranslation } from "react-i18next"
import { RenderEventContent } from "../EventContent"
import { IListOfProperties } from "../../CalenderView"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import { Events } from "../../CalenderView/types"
dayjs.extend(isBetween)

const daysIndices: Record<number, number> = {
	0: 3,
	1: 4,
	2: 5,
	3: 6,
	4: 7,
	5: 8,
	6: 2,
}
interface IProps {
	listOfProperties: IListOfProperties[]
	listOfPropertiesData: Events
	priceList:any
}
export default function Properties({
	listOfProperties,
	listOfPropertiesData,
	priceList
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const getDaysOfWeekValue = useRecoilValue(getDaysOfWeek)
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		i18n: { language },
	} = useTranslation()

	const PropertiesList = useMemo(() => {
		const data = listOfProperties?.map((item) => {
			return {
				propertyId: item?.propertyId,
				propertyName: item?.propertyName,
				propertyLocation: item?.propertyLocation,
				airbnbUnitId: item?.airbnbUnitId,
				gathrenUnitId: item?.gathrenUnitId,
			}
		})
		return data ?? []
	}, [listOfProperties])

	const filterEventsList = useMemo(() => {
		const data = listOfPropertiesData?.filter((item) => {
			const isEventStartDateInWeek = dayjs(
				item?.bookingInfo?.checkIn
			).isBetween(
				dayjs(getDaysOfWeekValue[0]).subtract(1, "days"),
				dayjs(getDaysOfWeekValue[6]).add(1, "days")
			)
			const isEventEndDateInWeek = dayjs(item?.bookingInfo?.checkOut).isBetween(
				dayjs(getDaysOfWeekValue[0]).subtract(1, "days"),
				dayjs(getDaysOfWeekValue[6]).add(1, "days")
			)
			const isEventTakeAllWeek =
				dayjs(getDaysOfWeekValue[0]).isBetween(
					dayjs(item?.bookingInfo?.checkIn),
					dayjs(item?.bookingInfo?.checkOut)
				) &&
				dayjs(getDaysOfWeekValue[6]).isBetween(
					dayjs(item?.bookingInfo?.checkIn),
					dayjs(item?.bookingInfo?.checkOut)
				)
			return (
				isEventStartDateInWeek || isEventEndDateInWeek || isEventTakeAllWeek
			)
		})
		return data
	}, [listOfPropertiesData, getDaysOfWeekValue])

	const resourcesOfData: any = useMemo(() => {
		const data = filterEventsList?.map((item) => {
			const eventEndDateIsFirstDayOfWeek = dayjs(
				item?.bookingInfo?.checkOut
			).isSame(getDaysOfWeekValue[0])

			const isEventStartDateInWeek = dayjs(
				item?.bookingInfo?.checkIn
			).isBetween(
				dayjs(getDaysOfWeekValue[0]).subtract(1, "days"),
				dayjs(getDaysOfWeekValue[6]).add(1, "days")
			)

			const isEventEndDateInWeek = dayjs(item?.bookingInfo?.checkOut).isBetween(
				dayjs(getDaysOfWeekValue[0]).subtract(1, "days"),
				dayjs(getDaysOfWeekValue[6]).add(1, "days")
			)

			const isEventTakeAllWeek =
				dayjs(getDaysOfWeekValue[0]).isBetween(
					dayjs(item?.bookingInfo?.checkIn).subtract(1, "days"),
					dayjs(item?.bookingInfo?.checkOut).add(1, "days")
				) &&
				dayjs(getDaysOfWeekValue[6]).isBetween(
					dayjs(item?.bookingInfo?.checkIn).subtract(1, "days"),
					dayjs(item?.bookingInfo?.checkOut).add(1, "days")
				)

			return {
				...item,
				continueToStart:
					!isEventStartDateInWeek &&
					isEventEndDateInWeek &&
					!eventEndDateIsFirstDayOfWeek,
				continueToEnd: isEventStartDateInWeek && !isEventEndDateInWeek,
				allWeek: isEventTakeAllWeek,
				eventEndDateIsFirstDayOfWeek: eventEndDateIsFirstDayOfWeek,
			}
		})
		return data ?? []
	}, [getDaysOfWeekValue, listOfPropertiesData])

	return (
		<>
			{PropertiesList.map((item) => {
				const propertyDates = priceList[ item.propertyId]

				return (
					<Fragment key={item.propertyName}>
						<div className='propertyName'>
							<div className='platforms'>
								{item?.airbnbUnitId && (
									<span>
										<AirbnbIcon />
									</span>
								)}
								{item?.gathrenUnitId && (
									<span>
										<GathernIcon />
									</span>
								)}
							</div>
							<div className='bookingInfo'>
								<p>{item?.propertyName}</p>
								<p>{item?.propertyLocation}</p>
							</div>
						</div>
						{getDaysOfWeekValue.map((item, index) => {
							let dayDeltails = {gatherinPrice:'',airbnbPrice:''}
							if(propertyDates && propertyDates[item]){
								dayDeltails =propertyDates[item] 
							}
						const currentPrice = dayDeltails?.gatherinPrice ||dayDeltails?.airbnbPrice || ''
							return (
								<div
									className={`${dayjs(new Date()).format("M-D") === dayjs(item).format("M-D") ? "currantDay" : ""}  propertyInfo`}
									key={index}
								>
								
									<div>{item && dayjs(item).format("D")}</div>
								{currentPrice ? 	<div>{`${currentPrice}ريال`}</div> : null}
								</div>
							)
						})}
					</Fragment>
				)
			})}

			{resourcesOfData?.map((item: any, index: number) => {
				const rowNumber = PropertiesList.findIndex(
					(property) =>
						property.propertyName === item?.bookingInfo?.propertyName
				)
				let columnStartNumber: number
				if (item.allWeek || item.continueToStart) {
					columnStartNumber = 2
				} else {
					columnStartNumber = daysIndices[dayjs(item.bookingInfo.checkIn).day()]
					// console.log(
					// 	"columnStartNumber",
					// 	dayjs(item.bookingInfo.checkIn).day()
					// )
				}

				let columnEndNumber: number
				if (item.allWeek || item.continueToEnd) {
					columnEndNumber = 9
					// console.log("checkOut", "allWeek")
				} else {
					// console.log("checkOut", "else")
					columnEndNumber = daysIndices[dayjs(item.bookingInfo.checkOut).day()]
					// console.log(item)
				}
				return (
					<RenderEventContent
						IslanguageAr={(language as "ar" | "en") === "ar"}
						rowNumber={rowNumber + 2}
						columnStartNumber={columnStartNumber}
						columnEndNumber={columnEndNumber}
						isContinueAfterWeek={item.continueToEnd}
						eventEndDateIsFirstDayOfWeek={item.eventEndDateIsFirstDayOfWeek}
						key={index}
						data={item}
					/>
				)
			})}
		</>
	)
}
