import CustomButton from "@Common/CustomButton"
import { SectionHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function SectionHeader() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<SectionHeaderWrapper>
			<div>
				<p>إدارة الفئات</p>
				<p>مساحة تعينك على إلقاء النظر على عقاراتك و إدارة مهامك</p>
			</div>
			<div>
				<CustomButton
					label={t("CATEGORYMANAGER.CREATECATEGORY")}
					withBG
					customPadding='8px 90px'
					onClick={() => navigate("/create-category-management")}
				/>
			</div>
		</SectionHeaderWrapper>
	)
}
