import { Dispatch, SetStateAction, useContext } from "react"
import { PopupDeleteCategoriesWrapper } from "./styles"
import { IoClose } from "react-icons/io5"
import { FaRegTrashAlt } from "react-icons/fa"
import { useCategoryMangment } from "@services"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useQueryClient } from "@tanstack/react-query"
import { GetListingManagementCategoriesUrl } from "../../../utils/constants"

interface IProps {
	setDeleteCategories: Dispatch<SetStateAction<boolean>>
	recordData:
		| {
				id: number
				categoryId: number
				categoryName: string
				propertyCounts: number
				channals: number[]
		  }
		| undefined
}
export default function PopupDeleteCategories({
	setDeleteCategories,
	recordData,
}: IProps) {
	const { showSuccess } = useContext(ToastMessageContext)
	const { deleteCategoryMutate } = useCategoryMangment()
	const queryClient = useQueryClient()

	const { mutateAsync: deleteCategory } = deleteCategoryMutate()
	const deleteCategoryFun = async () => {
		if (!recordData?.categoryId) return
		const data = await deleteCategory(recordData?.categoryId)
		showSuccess(data.data.result)
		setDeleteCategories(false)
		queryClient.invalidateQueries({
			queryKey: [GetListingManagementCategoriesUrl],
		})
	}
	return (
		<PopupDeleteCategoriesWrapper>
			<div className='popupContainer'>
				<div>
					<button onClick={() => setDeleteCategories(false)}>
						<IoClose />
					</button>
				</div>
				<div>
					<div>
						<FaRegTrashAlt />
					</div>
					<p>هل أنت متأكد أنك تريد حذف هذه الفئة؟</p>
				</div>
				<div>
					<button type='button' onClick={() => setDeleteCategories(false)}>
						الغاء
					</button>
					<button type='button' onClick={() => deleteCategoryFun()}>
						مسح
					</button>
				</div>
			</div>
		</PopupDeleteCategoriesWrapper>
	)
}
