export const GetFirstDayOfMonth = (date = new Date()) => {
	let start = new Date(date)
	let end = new Date(date)
	start.setDate(1);
	end.setDate(1);
	end.setMonth(end.getMonth() + 1)

	return {
		start,
		end
	};
}