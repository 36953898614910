import styled from "styled-components"

export const CustomButtonWrapper = styled.button<{
	$withBG: boolean
	$customPadding: string | undefined
	$fontSize: string | undefined
	$borderRadius: string | undefined
	$iconPosition?: "rtl" | "ltr"
	$borderColor?: string
}>`
	border: ${(props) => (props.$withBG ? "none" : "1px solid #8E98A1")};
  border-color: ${(props) => (props.$borderColor ? props.$borderColor : 'none')};
	background-color: ${(props) => (props.$withBG ? "#5b3fff" : "#fff")};
	color: ${(props) => (props.$withBG ? "#fff" : "#435363")};
	padding-block: 9px;
	padding: ${(props) =>
		props.$customPadding ? props.$customPadding : "9px 0"};
	border-radius: ${(props) =>
		props.$borderRadius ? props.$borderRadius : "20px"};
	width: 100%;
	font-size: ${(prosp) => (prosp.$fontSize ? "16px" : "")};
	font-weight: 700;
	line-height: 19.2px;
	direction: ${(props) => (props.$iconPosition ? props.$iconPosition : "rtl")};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 8px;
	white-space: nowrap;

	cursor: pointer;
`
