import { FormProvider, useForm } from "react-hook-form"
import { useEffect, useMemo } from 'react'
import { IProperty } from "../.."
import { DaysOfStayTabWrapper } from "./styles"
import CustomSelect from "@Common/CustomSelect"
import { useTranslation } from "react-i18next"
import CustomButton from "@Common/CustomButton"
import CustomDatePicker from "@Common/CustomDatePicker"
import { Grid } from "@mui/material"
import { useAdvanceSettings } from "@services"
import { useContext, useState } from "react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import dayjs from "dayjs"
import { useRecoilState, useSetRecoilState } from "recoil"
import { controleAdvancedSettings, reinitializeCalendar } from "@store/index"
import { LoadingWrapper } from '@components';
import { Box, Button, MenuItem, Select, Typography, RadioGroup, Radio } from '@mui/material';
import { useCategoryMangment } from "@services"
import Input from "@Common/input"
import MultiSelect from "@Common/MultiSelect"

const GatherinLabel = 'GatherIn'
const AirbnbLabel = 'Airbnb' 
interface IProps {
	PropertyList: IProperty[]
	onClose: () => void
	onSuccess?: () => void
	selectedProperyId?: string
	dateRange?: any

}
interface IForm {
	listing_id: string
	from: string
	to: string
	availability: string

}
export default function DaysOfStayTab({ PropertyList, onClose = () => { }, onSuccess = () => { }, selectedProperyId, dateRange }: IProps) {
	const { t } = useTranslation()
	const [isCategorySelected, setIsCategorySelected] = useState(false)
	const [selectedProperties, setSelectedProperties] = useState<any>([])
	const [selectedChannels, setSelectedChannels] = useState<any>([])
	const [channelError, setChannelError] = useState(false)


	const [open, setOpen] = useState(dateRange?.open)
	const controleAdvancedSettingsSetValue = useSetRecoilState(
		controleAdvancedSettings
	)
	const [reinitializeCalendarValue, reinitializeCalendarSetValue] =
		useRecoilState(reinitializeCalendar)

	const methods = useForm()
	const { watch, setValue, getValues } = methods
	const {

		AllChannleseAvailabilityMutate

	} = useAdvanceSettings()
	const { showError, showSuccess } = useContext(ToastMessageContext)
	useEffect(() => {

		setValue('listing_id', String(selectedProperyId))
		let selectedName = PropertyList.find((x: any) => x.value == selectedProperyId)?.label
		setSelectedProperties([selectedName])
		setSelectedChannels([])
	}, [selectedProperyId])
	const connectedChannels = useMemo(() => {

		let chaanles: any = []
		let isAllSelected = selectedProperties?.includes(t('COMMON.All'))
		let base =isAllSelected ? PropertyList: selectedProperties
		base.map((xx: any,i:any) => {
			let current = PropertyList.find((x: any,ii:any) => {
				if(isAllSelected){
					return i == ii
				}
				return x.label == xx
			})
			if (current?.gathrenUnit?.length) {
				if (!chaanles.includes(GatherinLabel)) {
					chaanles.push(GatherinLabel)
				}

			}
			if (current?.airbnbUnit?.length) {
				if (!chaanles.includes(AirbnbLabel)) {
					chaanles.push(AirbnbLabel)
				}


			}
		})
		return chaanles
	}, [selectedProperties, selectedProperties])

	const { mutate, isPending: pending1 } = AllChannleseAvailabilityMutate({
		onSuccess: () => {
			if (watch("availability") === "0") {
				showSuccess(t("CALENDAR.CLOSE_AVAILABILITY_SUCCESS"))
			} else {
				showSuccess(t("CALENDAR.OPEN_AVAILABILITY_SUCCESS"))
			}
			onSuccess()
		},
		onError: () => showError(t("ERRORS.ERROR_500_MESSAGE")),
	})


	const { GetCategoriesQuery } = useCategoryMangment()
	const { data: GetCategories } = GetCategoriesQuery({
		pageNumber: '1',
		pageSize: '30',
		search: '',
	})
	const onSubmit = async (value: any) => {
		setChannelError(selectedChannels?.length == 0 )
		if (selectedChannels?.length == 0) return
		if (open == '-1') return
		let categoryChannelId = value.category ? GetCategories?.data?.result?.find((x: any) => x.categoryId == value.category)?.connectedChannels[0]?.channelId : null
		const SelectedProperty = PropertyList.filter(
			(item) => item.value.toString() === value.listing_id.toString()
		)
		let selectedChannel: any = []
		if (SelectedProperty[0].airbnbUnit?.length && !SelectedProperty[0].gathrenUnit?.length) {
			selectedChannel = ['airbnb']

		}
		else if (!SelectedProperty[0].airbnbUnit?.length && SelectedProperty[0].gathrenUnit?.length) {
			selectedChannel = ['gatherin']
		} else {
			selectedChannel = ['airbnb', 'gatherin']
		}
		selectedChannel = value.category ? [categoryChannelId] : selectedChannel
		let propertiesIds = selectedProperties
		propertiesIds = selectedProperties?.includes(t('COMMON.All')) ? PropertyList.map((x: any) => x.value) : propertiesIds.map((x: any) => {
			return PropertyList.find((x: any) => x.value == selectedProperyId)?.value

		})
		let channelsMapper = selectedChannels?.includes(t('COMMON.All')) ? connectedChannels : selectedChannels
		channelsMapper =channelsMapper.map((x:any)=>{
			if(x==AirbnbLabel)return 1
			if(x==GatherinLabel)return 2
			return 3
		})

		mutate({
			propertiesId: value.category ? [] : propertiesIds,
			categoriesId: value.category ? [value.category] : [],
			date_from: dayjs(value?.date_from)?.format("YYYY-MM-DD"),
			date_to: dayjs(value?.date_to)?.format("YYYY-MM-DD"),
			availability: String(open),
			connectedChannels:channelsMapper

		})

	}
	const { monthStartDate, monthEndDate } = dateRange
	useEffect(() => {
		if (monthStartDate) {
			setValue('date_from', new Date(monthStartDate as Date))

		}
		if (monthEndDate) {
			setValue('date_to', new Date(monthEndDate as Date))
		}



	}, [monthStartDate, monthEndDate])

	return (
		<DaysOfStayTabWrapper>
			<LoadingWrapper loading={pending1} >

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>

						<div>
							<Grid container spacing={"12px"}>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Typography sx={{
											color: '#261B6A',
											fontWeight: 500,
											fontSize: '20px',
											mb: 1
										}} >
											{t('COMMON.DATE')}
										</Typography>
									</Grid>
									<Box sx={{ display: 'flex' }} >
										<CustomDatePicker
											value={getValues('date_from')}
											name='date_from'
											label={t("GENERAL.FROM")}
											registerOptions={{
												required: {
													value: true,
													message: '',
												},
											}}
										/>
										<Box width={20} />
										<CustomDatePicker
											value={getValues('date_from')}
											name='date_to'
											label={t("CALENDAR.TO")}
											registerOptions={{
												required: {
													value: true,
													message: '',
												},
											}}
										/>
									</Box>

								</Grid>

								<Grid item xs={12}>


									{
										isCategorySelected ? <CustomSelect
											name='category'
											label="التنصيف"
											registerOptions={{
												required: {
													value: true,
													message: t("COMMON.REQUIRED"),
												},
											}}
											data={GetCategories?.data?.result?.map((x: any) => {
												return {
													value: x?.categoryId,
													label: x?.categoryName,

												}
											}) ?? []}
										/> : <Box>

											<MultiSelect
												label={t('COMMON.PROPERTY')}
												placeholder={t('COMMON.PROPERTY')}
												values={selectedProperties}
												setValues={(v: any) => setSelectedProperties(v)}
												data={PropertyList.length == 0 ? [] : [
													t('COMMON.All'),
													...PropertyList?.map((x: any) => x?.label)
												]} />
											<MultiSelect
												isError={channelError}
												placeholder={t('COMMON.Select_channel')}
												label={t('COMMON.CHANNEL')}
												values={selectedChannels}
												setValues={(v: any) => {
													setSelectedChannels(v)
													setChannelError(false)
												}}
												data={PropertyList.length == 0 ? [] : [
													t('COMMON.All'),
													...connectedChannels
												]} />


										</Box>
									}
								</Grid>


								<Grid item xs={12}>

									<>
										<Typography onClick={() => {
										}} mb={1}>
											<span className='font-semibold'>{t('COMMON.AVAILABILITY_STATUS')}</span>
											{open == '-1' && <span style={{ color: 'red', marginRight: '3px', marginLeft: '3px', fontWeight: 'bold' }} >*</span>}
										</Typography>

										<RadioGroup value={open} onChange={(e => setOpen(e.target.value))} aria-labelledby='min-stay-radios' name='availability' >
											<div className='flex items-center cursor-pointer'>
												<Radio value={0} />
												<Typography>{`${t('CALENDAR.AVAILABILITY_CLOSE')}`}</Typography>
											</div>
											<div className='flex items-center cursor-pointer'>
												<Radio value={1} />
												<Typography>{`${t('CALENDAR.AVAILABILITY_OPEN')}`}</Typography>
											</div>
										</RadioGroup>
										<Box sx={{ mt: 1 }} />
										<Input
											name='search'
											label={t('COMMON.STAY_DURATION')}
											placeholder={t('COMMON.ENTER_NUMBER')}
											type='number'
											withOutErrorMessage
											suffixLabel={t('COMMON.SATY_NIGHTS_PLACEHOLDER')}
										/>
									</>
								</Grid>



							</Grid>
						</div>

						<div className='ContainerFooter'>
							<CustomButton label={t("SAVE")} withBG type='submit' />
							<CustomButton
								label={t("GENERAL.CANCEL_ALT")}
								onClick={() => {
									reinitializeCalendarSetValue(reinitializeCalendarValue + 1)
									controleAdvancedSettingsSetValue(false)
									onClose()
								}}
							/>
						</div>
					</form>
				</FormProvider>
			</LoadingWrapper>

		</DaysOfStayTabWrapper>
	)
}
