import WhiteCard from "@Common/WhiteCard"
import CalenderView from "./CalenderView"
import SectionControls from "./components/Controls"
import { useState } from "react"

export default function WeeklyCalendarModal() {
	const [channel, setChannel] = useState<"1" | "2" | "3">("1")
	return (
		<WhiteCard>
			<div>
				<SectionControls channel={channel} setChannel={setChannel} />
				<CalenderView channel={channel} />
			</div>
		</WhiteCard>
	)
}
