import styled from "styled-components"

export const PricingTabWrapper = styled.div`
	height: 100%;
	form {
		display: grid;
		grid-template-rows: 1fr auto;
		height: 100%;
	}
`
