import styled from "styled-components"

export const AdvancedSettingsWrapper = styled.div`
	height: 100%;
	.containerTab {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		div {
			width: calc(100% / 5);
			border-bottom: 1px solid #adbac7;
			white-space: nowrap;
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;
			font-weight: 300;
			line-height: 16px;
			text-align: center;
			padding-block: 10px;
			color: #adbac7;
			&.activeTab {
				color: #5b3fff;
				border-bottom: 1px solid #5b3fff;
				font-weight: 400;
			}
		}
	}

	.containerContent {
		margin-top: 16px;
		height: calc(100% - 37px);
	}
	.ContainerFooter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		margin-top: 40px;
		margin-bottom: 20px;
	}
`
