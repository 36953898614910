import { useTranslation } from "react-i18next"
import { AdvancedSettingsWrapper } from "./styles"
import { useMemo, useState } from "react"
import PricingTab from "./components/pricing"
import AvailabilityTab from "./components/AvailabilityTab"
// import DayOfStayTab from "./components/DayOfStayTab"
// import InstantBookingTab from "./components/InstantBooking"
// import DiscountsTab from "./components/Discounts"

import WhiteCard from "@Common/WhiteCard"
import { useProperty } from "@services"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import {
	checkIfBusy,

} from '../../../../pages/Calendar/utlis'

export interface IProperty {
	value: string
	label: string
	id: string
	airbnbUnit: string
	gathrenUnit: string
	bookingUnitId?: string
}
interface iProps {
	onClose?: () => void
	onRefresh?: () => void
	selectedProperyId?: string
	selectedCell?: any
	date?: any
	selectedPrices?: any
	allCalendar?: any
	DemoListing?: any
	selectedFlatIndx?: any

}
export default function AdvancedSettings({ onRefresh = () => { }, onClose = () => { },
	selectedProperyId,
	selectedCell,
	date,
	selectedPrices,
	allCalendar,
	DemoListing,
	selectedFlatIndx
}: iProps) {
	const [activeTab, setActiveTab] = useState(1)
	const { t } = useTranslation()
	const dateRange = useMemo(() => {
		let sorted = selectedCell?.sort(function (a: number, b: number) { return a - b })
		let startMonth = selectedCell?.length ? sorted[0] : null
		let endMonth = selectedCell?.length ? sorted[selectedCell?.length - 1] : null
	

		let monthStartDate = null
		let monthEndDate = null
		if (startMonth) {
			let d = new Date(date)
			d.setDate(startMonth)
			monthStartDate = d
		}
		if (endMonth) {
			let dd = new Date(date)
			dd.setDate(endMonth)
			monthEndDate = dd
		}
		var list = [];
		let open = []
		let closed = []
		if (sorted.length == 1) {
			
			let dd = new Date(date)
			dd.setDate(sorted[0])
			let isBlanck = checkIfBusy(dd, allCalendar, DemoListing[selectedFlatIndx]?.propertyName, DemoListing)
			if (isBlanck) {
				closed.push('1')
			} else {
				open.push('1')
			}
		}
		if (sorted?.length > 1) {
			for (var i = sorted[0]; i <= sorted[sorted.length - 1]; i++) {
				let dd = new Date(date)
				dd.setDate(i)
				let isBlanck = checkIfBusy(dd, allCalendar, DemoListing[selectedFlatIndx]?.propertyName, DemoListing)
				list.push(i);
				if (isBlanck) {
					closed.push(1)
				} else {
					open.push(1)
				}
			}
		} else {

		}

		return {
			monthStartDate,
			monthEndDate,
			open: closed.length > 0 && open.length > 0 ? '-1' : closed.length > 0 ? '0' : '1'
		}
	}, [date, selectedCell])

	const tabs = [
		{
			label: t("CALENDAR.PRICING"),
			id: 1,
		},

		// {
		// 	label: t("CALENDAR.DISCOUNTS"),
		// 	id: 2,
		// },
		{
			label: t("الاتاجة"),
			id: 3,
		},
		// {
		// 	label: t("الاقامة"),
		// 	id: 4,
		// },
		// {
		// 	label: t("CALENDAR.INSTANT_BOOK_SETTINGS"),

		// 	id: 5,
		// },
	]
	/* ---------------------------------- Hooks --------------------------------- */
	const { getManagementListQuery } = useProperty()
	const { data: managementList } = getManagementListQuery()
	const listData: IProperty[] = useMemo(() => {
		const data = managementList?.map((item: any) => {
			return {
				value: item.propertyId,
				label: item.propertyName,
				id: item.airbnbUnitId || item.gathrenUnitId,
				airbnbUnit: item.airbnbUnitId ? "airbnbUnit" : "",
				gathrenUnit: item.gathrenUnitId ? "gathrenUnit" : "",
				bookingUnitId: item?.bookingUnitId ? "bookingUnitId" : "",	
				connectedChannels:item?.connectedChannels

			}
		})
		const filteData = data?.filter(
			(item: any) => activeTab == 1 || activeTab === 3 ? item.airbnbUnit || item.gathrenUnit || item.bookingUnitId : item.airbnbUnit || item.gathrenUnit
		)
		return filteData
	}, [getManagementListQuery, activeTab])

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setActiveTab(Number(newValue));
	};
	return (
		<WhiteCard>
			<AdvancedSettingsWrapper>
				{/* <div className='containerTab'>
					{tabs.map((item) => {
						return (
							<div
								onClick={() => setActiveTab(item.id)}
								key={item.id}
								className={`${activeTab === item.id ? "activeTab" : ""}`}
							>
								{item.label}
							</div>
						)
					})}
				</div> */}

				<Tabs
					value={activeTab}
					onChange={handleChange}
					aria-label="wrapped label tabs example"
					sx={{ width: '100%' }}
				>

					<Tab sx={{ width: '50%' }} value={1} label={t("CALENDAR.PRICING")} />
					<Tab sx={{ width: '50%' }} value={3} label={t("CALENDAR.AVAILABILITY")} />
				</Tabs>

				<div className='containerContent'>
				<Box sx={{ minWidth: window.innerWidth / 5 }} >
					{activeTab === 1 && 
						<PricingTab selectedPrices={selectedPrices} dateRange={dateRange} selectedProperyId={selectedProperyId} onSuccess={onRefresh} onClose={onClose} PropertyList={listData} />}
					{/* {activeTab === 2 && <DiscountsTab onSuccess={onRefresh} onClose={onClose} PropertyList={listData} />} */}
					{activeTab === 3 &&
						<AvailabilityTab key={dateRange?.open} dateRange={dateRange} selectedProperyId={selectedProperyId} onSuccess={onRefresh} onClose={onClose} PropertyList={listData} />
					}
					{/* {activeTab === 4 && <DayOfStayTab onSuccess={onRefresh} onClose={onClose} PropertyList={listData} />} */}
					{/* {activeTab === 5 && <InstantBookingTab onSuccess={onRefresh} onClose={onClose} PropertyList={listData} />} */}
					</Box>
				</div>
			</AdvancedSettingsWrapper>
		</WhiteCard>
	)
}
