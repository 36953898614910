import { IoInformationCircleOutline } from "react-icons/io5"
import { ContainerCardWrapper } from "./styles"
import { useReservations } from "@services"

export default function CardsInfo() {
	const { ReservationCardQuery } = useReservations()
	const { data: ReservationCard } = ReservationCardQuery()

	const data = [
		{
			title: "عدد الحجوزات",
			count: ReservationCard?.totalReservationsCount,
			info: "",
		},
		{
			title: "حجوزات يدوية",
			count: ReservationCard?.manualReservationsCount,
			info: "",
		},
		{
			title: "حجوزات الية",
			count: ReservationCard?.automaticReservationsCount,
			info: "",
		},
		{
			title: "حجوزات قيد الانتظار",
			count: ReservationCard?.pendingReservationsCount,
			info: "",
		},
		{
			title: "حجوزات ملغية",
			count: ReservationCard?.cancelledReservationsCount,
			info: "",
		},
	]

	return (
		<ContainerCardWrapper>
			{data.map((item) => {
				return (
					<div className='containerCard'>
						<div>
							<p>{item.title}</p>
							<div>
								<IoInformationCircleOutline />
							</div>
						</div>
						<div>
							<p>{item.count}</p>
						</div>
					</div>
				)
			})}
		</ContainerCardWrapper>
	)
}
