import { IoInformationCircleOutline } from "react-icons/io5"
import { ContainerCardWrapper } from "./styles"
interface IProps {
	data: IData
}
interface IData {
	blacklistedGuests: number | undefined
	repeatedGuests: number | undefined
	totalGuests: number | undefined
}
export default function CardsInfo({ data }: IProps) {
	const dataList = [
		{
			title: "عدد الضيوف",
			count: data?.totalGuests,
			info: "",
		},
		{
			title: "الضيوف المتكررة زيارتهم",
			count: data?.repeatedGuests,
			info: "",
		},
		{
			title: "الضيوف المحظورة",
			count: data?.blacklistedGuests,
			info: "",
		},
	]

	return (
		<ContainerCardWrapper>
			{dataList.map((item) => {
				return (
					<div className='containerCard'>
						<div>
							<p>{item.title}</p>
							<div>
								<IoInformationCircleOutline />
							</div>
						</div>
						<div>
							<p>{item.count}</p>
						</div>
					</div>
				)
			})}
		</ContainerCardWrapper>
	)
}
