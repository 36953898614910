import { MultiChannelWrapper } from "./styles"
import { useTranslation } from "react-i18next"

import Input from "@Common/input"

import { useFormContext } from "react-hook-form"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useEffect, useMemo, useState } from "react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import { Box, Typography } from "@mui/material" 

interface IFormAirBnb {
	rate: string
	date_from: string
	date_to: string
}
export default function MultiChannel({
	isConnectedWithBooking = false,
	isConnectedWithAirbnb = false
}) {
	const { t } = useTranslation()
	const { watch, setValue } = useFormContext<IFormAirBnb>()
	const [showrange, setShowRange] = useState(false)
	const [range, setRange] = useState<any>({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	})
	const date_from_watch = watch("date_from")
	const Channels = useMemo(() => {
		let result: any = []
		if (isConnectedWithAirbnb) {
			result.push(

				{
					label: "Airbnb",
					value: "1",
				}
			)
		}
		if (isConnectedWithBooking) {
			result.push(

				{
					label: "Booking",
					value: "2",
				},
			)
		}

		return result
	}, [isConnectedWithAirbnb, isConnectedWithBooking])

	return (
		<MultiChannelWrapper>
		<Box sx={{display:'flex'}} >
				<AirbnbIcon />
				<Typography sx={{
					color: '#435363',
					fontWeight: 700,
					lineHeight: '23px',
					fontSize: '14px',
					mx:1
				}} >
					Airbnb
				</Typography>
			</Box>
			<div className='containerFormInputs'>
				

					<div className='containerInputs'>
						<Box sx={{display:'flex',justifyContent:'space-between'}} >

				
						
					<label>{t("COMMON.PRICING")}</label>  
						 <Input 
							suffixLabel={t('COMMON.RS')} 
							name='rate'
							placeholder={t("PRICE")}
							min={38}
							registerOptions={{
								required: {
									value: true,
									message: '',
								},
								min: {
									value: 38,
									message: "اقل قيمه 38",
								},
							}}
						/>
								</Box>
					</div>
			</div>
		</MultiChannelWrapper>
	)
}
