// @ts-nocheck

import { controleBookingDetails, gitBookingInfo } from "@store/index"
import { BookingDetailsWrapper } from "./styles"
import { useRecoilValue, useSetRecoilState } from "recoil"
import AirbnbIcon from "@assets/svg/airbnb-icon.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import TagUser from "@assets/svg/TagUser.svg?react"
import WhiteCard from "@Common/WhiteCard"
import { useTranslation } from "react-i18next"
import CustomButton from "@Common/CustomButton"
import { useEffect, useState } from "react"
import { LoadingWrapper } from "@components"
import {
	Box,

} from "@mui/material"
import { useCalendar, useProperty } from "@services"
import BookingInfoLockDetails from './BookingInfoLockDetails'

export default function BookingDetails() {
	const gitBookingInfoState = useRecoilValue(gitBookingInfo)
	const controleBookingDetailsState = useSetRecoilState(controleBookingDetails) 
	const { getManagementListQuery, gathrenListingQuery, getAirbnbListingQuery } =
	useProperty()
const { data: managementList } = getManagementListQuery()
const selectedProperty = managementList.find((x:any)=>x.propertyId ==gitBookingInfoState?.bookingInfo?.propertyId	)

const { airbnbListing, gathernListing,sendmessageQuery } = useCalendar()

const {
	data: gathernListingData,

} = gathernListing()
const {
	data: airbnbListingData,
} = airbnbListing()
const CheckIfMessageSentForReservationId = (id='') => {
	let isSent = false
	airbnbListingData?.map((ww:any) => {
		ww?.calendarResponse?.map((x:any) => {
			if (x?.bookingInfo?.reservationId == id) {
				isSent = x?.bookingInfo?.isMessageSend
			}
		})
	})
	if (!isSent) {
		gathernListingData?.map((x:any) => {
			x?.dates?.map((xx:any) => {
				if (xx?.reservation_id == id) {
					isSent = xx?.isMessageSend
				}
			})
		})
	}

	return isSent
}
	const { t } = useTranslation()

	useEffect(() => {
		console.log('gitBookingInfoState',gitBookingInfoState)
	}, [gitBookingInfoState])
	const isAutoAccess = true || selectedProperty?.isAutoAccess
	const [messageSent, setMessageSent] = useState(false)

useEffect(()=>{
	setMessageSent(	CheckIfMessageSentForReservationId( gitBookingInfoState?.bookingInfo?.reservation_id) )

},[])

	const { data, isLoading, refetch, error, errorUpdatedAt } = sendmessageQuery(
		gitBookingInfoState?.bookingInfo?.reservation_id ?? ''
	)
	useEffect(() => {
		if (data) {
			setMessageSent(true)
		}
	}, [data])
	return (
		<WhiteCard>
			<BookingDetailsWrapper>
				<div>
					<div>
						<span>
							{gitBookingInfoState?.eventFlag === "airbnb" && <AirbnbIcon />}
							{gitBookingInfoState?.eventFlag === "gathern" && <GathernIcon />}
						</span>
						<span>
							{(gitBookingInfoState?.eventFlag === "airbnb" && "airbnb") ||
								(gitBookingInfoState?.eventFlag === "gathern" && "gathern")}
						</span>
					</div>
					<div>
						<span></span>
						<span>
							{gitBookingInfoState?.bookingInfo.status ||
								gitBookingInfoState?.availability_type}
						</span>
					</div>
				</div>

				<div>
					<div>
						<p>
							<span>
								<TagUser />
							</span>
							<span>{t("NEW_LOCKS.GUEST_DETAILS")}</span> 
						</p>
					</div>
					<div>
						<p>
							{gitBookingInfoState?.bookingInfo?.guestName ||
								gitBookingInfoState?.bookingInfo?.user_name}
						</p>
						<p>
							{gitBookingInfoState?.bookingInfo?.clientNumber ||
								gitBookingInfoState?.bookingInfo?.guestPhoneNumber}
						</p>
					</div>
				</div>

				<div>
					<div>
						<span>{t("CALENDAR.BOOKING_NUMBER")}</span>
						<span>
							{gitBookingInfoState?.bookingInfo?.reservation_id ||
								gitBookingInfoState?.bookingInfo?.reservationNumber}
						</span>
					</div>
					<p>{t("CALENDAR.BOOKING_DATE")}</p>
					<div>
						<div>
							<span>{t("NEW_LOCKS.ENTRY")}</span>
							<span>{gitBookingInfoState?.bookingInfo?.checkIn}</span>
						</div>
						<div>
							<span>{t("CALENDAR.CHECK_OUT")}</span>
							<span>{gitBookingInfoState?.bookingInfo?.checkOut}</span>
						</div>
					</div>
				</div>
				{isAutoAccess ? (
							<>
								<Box
									sx={{ height: "1px", backgroundColor: "#F0F1F2", marginY: 1 }}
								/>
								<Box>
									<Box
										sx={{
											color: "#435363",
											fontSize: 14,
											fontWeight: 500,
											mt: 1,
										}}
									>
										{t("NEW_LOCKS.ROOM_AVAILABLE")}
									</Box>
									{messageSent ? (
										<Box
											sx={{
												color: "#8E98A1",
												fontWeight: 500,
												fontSize: 14,
												marginBottom: 1,
											}}
										>
											{t("NEW_LOCKS.WELCOME_MSSAGE_SENT")}
										</Box>
									) : (
										<LoadingWrapper loading={isLoading}>
											<Box
												onClick={() => refetch()}
												sx={{
													backgroundColor: "#5A3FFE",
													width: "95%",
													marginRight: "auto",
													marginLeft: "auto",
													height: "44px",
													color: "white",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													fontWeight: 700,
													fontSize: "16px",
													borderRadius: "20px",
													marginBottom: 2,
													marginTop: 1,
													cursor: "pointer",
												}}
											>
												{t("NEW_LOCKS.SEND_MESSAGE")}
											</Box>
										</LoadingWrapper>
									)}
								</Box>
							</>
						) : null}
						<BookingInfoLockDetails 
							id={selectedProperty?.propertyId??''}
							propertyName={selectedProperty?.propertyName}
						  
						/>
				<div className='ContainerFooter'>
					<CustomButton
						label={t("GENERAL.CANCEL_ALT")}
						onClick={() => controleBookingDetailsState(false)}
					/>
				</div>
			</BookingDetailsWrapper>
		</WhiteCard>
	)
}
