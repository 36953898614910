// @ts-nocheck
import { format } from 'date-fns';

export const checkIfBusy = (date, searchable, propertyName, confirmedBookingDetails,byChannles=false) => { 
  
	let closedGatherin=false
   let closedAirbnb=false
	let alreadyBooked = false
	confirmedBookingDetails.filter(x => x.propertyName == propertyName).map(zzz => {
	  zzz?.dates?.map(www => {
		www?.days?.map(currnetDate => {
		  if (format(date, "dd-MM-yyyy") == format(currnetDate, "dd-MM-yyyy")) {
			alreadyBooked = true
  
		  }
		})
	  })
  
	})
	if (alreadyBooked){
		if(byChannles){
			return {
				closedGatherin,
				closedAirbnb

			}
		}
		return false
	}
	let isBusy = false
	searchable?.filter(x => x?.propertyName == propertyName).map(c => {
	  c?.dates?.map(current => {
		let isSameDate = format(date, "dd-MM-yyyy") == format(current?.date, "dd-MM-yyyy")
		if (isSameDate &&current?.gathren_day_close || (isSameDate &&current?.airbnb_day_close)
		// current?.availability !== "available"
		 ) {
		  isBusy = true
		   closedGatherin=current?.gathren_day_close
		   closedAirbnb=current?.airbnb_day_close
		  return
		}
	  })
	})
  if(byChannles){
	return {
		closedGatherin,
		closedAirbnb

	}
  }
  
	return closedGatherin || closedAirbnb
  
  }