import CustomTable from "@Common/CustomTable"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import CardsInfo from "./cardsInfo"
import { GuestManagementWrapper } from "./styles"
import GuestMangmentHeader from "./guestMangmentHeader"
import { useGuestManagement } from "@services"
import SelectClassfication from "./selectClassfication"
import PopupReservationsList from "./popupReservationsList"
import { GustReservation } from "src/services/useGuestManagement/types"
import PopupConfirmation from "./popupConfirmation"

export default function GuestManagement() {
	const [classficationPopup, setClassficationPopup] = useState(false)

	const [reservationsList, setReservationsList] = useState<GustReservation[]>(
		[]
	)
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [perPage, onPerpageChange] = useState("20")
	const [activePage, setActivePage] = useState(1)
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const { GuestsQuery } = useGuestManagement()
	const { data: Guests } = GuestsQuery({
		pageNumber: activePage.toString(),
		pageSize: perPage,
	})

	const columns = [
		{
			field: "name",
			headerName: "الاسم",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.name}
				</p>
			),
		},
		{
			field: "classification",
			headerName: "التصنيف",
			flex: 1,
			renderCell: (params: any) => (
				<SelectClassfication
					data={params.row.classification}
					guestName={params.row.name}
					setClassficationPopup={setClassficationPopup}
				/>
			),
		},
		{
			field: "numberOfTotalVisit",
			headerName: "عدد الزيارات",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.numberOfTotalVisit}
				</p>
			),
		},
		{
			field: "phoneNumber",
			headerName: "رقم الجوال",
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.phoneNumber}
				</p>
			),
		},
		{
			field: "visitedProperties",
			headerName: "المنطقة",
			flex: 1,
			renderCell: (params: any) => (
				<p style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}>
					{params.row.visitedProperties}
				</p>
			),
		},
		{
			field: "reservations",
			headerName: "حجوزات العقارات",
			flex: 1,
			renderCell: (params: any) => (
				<div className='containerPopupAction'>
					<p
						className='columnsContent'
						style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
						onClick={() => {
							setReservationsList(params.row.reservations)
							setIsPopupOpen(true)
						}}
					>
						عرض
					</p>
				</div>
			),
		},
	]

	const rows = useMemo(() => {
		const data = Guests?.data?.result?.guests?.map((item, index) => {
			return {
				id: index,
				name: item.name,
				classification: item.classification,
				numberOfTotalVisit: item.numberOfTotalVisit,
				phoneNumber: item.phoneNumber,
				visitedProperties: item.visitedProperties,
				reservations: item.reservations,
			}
		})
		return data
	}, [Guests])

	const data = {
		blacklistedGuests: Guests?.data?.result?.blacklistedGuests,
		repeatedGuests: Guests?.data?.result?.repeatedGuests,
		totalGuests: Guests?.data?.result?.totalGuests,
	}
	return (
		<GuestManagementWrapper>
			{isPopupOpen && (
				<PopupReservationsList
					reservationsList={reservationsList}
					setIsPopupOpen={setIsPopupOpen}
				/>
			)}
			{classficationPopup && <PopupConfirmation setClassficationPopup={setClassficationPopup} />}
			<GuestMangmentHeader />
			<CardsInfo data={data} />
			<div className='containerTable'>
				<CustomTable
					columns={columns}
					rows={rows ?? []}
					HeaderTheTable={t("GUESTLIST")}
					totalPages={
						Guests?.data?.totalRecords
							? Math.ceil(Guests?.data?.totalRecords / Number(perPage))
							: 1
					}
					setActivePage={setActivePage}
					activePage={activePage}
					onPerpageChange={onPerpageChange}
					perPage={perPage}
					listPerPagesNumber={[
						{
							label: "20",
							value: "20",
						},
						{
							label: "50",
							value: "50",
						},
						{
							label: "100",
							value: "100",
						},
					]}
				/>
			</div>
		</GuestManagementWrapper>
	)
}
