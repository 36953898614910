import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { IMessage, IProperty, ITemplate } from '@interfaces';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProperty } from '@services';
import { Clear } from '@mui/icons-material';
import { useIsMutating } from '@tanstack/react-query';
import { modalBoxStyle, modalContainerStyle } from '@utils';
import { SelectWelcomeMessage } from './SelectWelcomeMessage';
import { SelectInstructions } from './SelectInstructions';
import { SelectFarewellMessage } from './SelectFarewellMessage';
import { SelectProperty } from './SelectProperty';
import { PreviewTemplate, TemplateMobileView } from '@components';
const AddMessageModal: FC<{
  open: boolean;
  rule: IMessage[];
  data?: ITemplate;
  farewell: IMessage[];
  welcome: IMessage[];
  handleClose: () => void;
  usedProperty?: IProperty[];
  onSuccess: (body: ITemplate) => void;
}> = ({ open, rule = [], farewell = [], welcome = [], data, handleClose, onSuccess, usedProperty }) => {
  const { t } = useTranslation();
  const [started, setStarted] = useState(Boolean(data));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [formName, setFormName] = useState<string | undefined>(data?.name);
  const [welcomeMessage, setWelcomeMessage] = useState<number | undefined>(data?.welomeMessageId);
  const [rules, setRules] = useState<number[]>(data?.ruleMessages?.map((i) => i.id) ?? []);
  const [farewellMessage, setFarewellMessage] = useState<number | undefined>(data?.farewellMessageId);
  const [properties, setProperties] = useState<IProperty[]>(data?.property ?? []);
  const mutating = useIsMutating();
  const validation: Record<number, boolean> = {
    0: true,
    1: Boolean(welcomeMessage),
    2: rules.length > 0,
    3: Boolean(farewellMessage),
  };
  const { getManagementListQuery } = useProperty();

  const { data: propertiesList } = getManagementListQuery();

  const handleSend = () => {
    onSuccess({
      id: data?.id,
      name: formName!,
      welcomeMessageId: welcomeMessage!,
      farewellMessageId: farewellMessage!,
      ruleMessageIds: rules,
      propertyIds: properties.map((p) => p.propertyId) ?? [],
    });
  };

  const handleTab = (selectedTab: number) => {
    if (validation[selectedTab]) {
      setTab(selectedTab);
    }
  };

  return (
    <>
      <Modal
        open={open}
        sx={modalContainerStyle}
        onClose={handleClose}
        aria-labelledby='add-message-modal-title'
        aria-describedby='add-message-modal-description'
      >
        <Box sx={{ ...modalBoxStyle, ...(started ? { height: '80vh' } : {}) }}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t(data?.id ? 'MESSAGING.UPDATE_FORM' : 'MESSAGING.CREATE_FORM')}
            </Typography>
          </div>
          {started ? (
            <>
              {tab <= 3 && (
                <Tabs
                  sx={{
                    mt: 2,
                    borderBottom: '2px solid var(--primary)',
                    '.MuiButtonBase-root': {
                      fontSize: '12px',
                      p: 1,
                      fontWeight: 'bold',
                    },
                  }}
                  onChange={(event, value) => handleTab(value)}
                  variant={'fullWidth'}
                  value={tab}
                >
                  <Tab label={t('MESSAGING.WELCOME_MESSAGE')} />
                  <Tab label={t('MESSAGING.INSTRUCTIONS')} disabled={!validation[1]} />
                  <Tab label={t('MESSAGING.FAREWELL')} disabled={!validation[2] || !validation[1]} />
                  <Tab
                    label={t('MESSAGING.PROPERTIES')}
                    disabled={!validation[3] || !validation[2] || !validation[1]}
                  />
                </Tabs>
              )}
              {tab === 0 && (
                <SelectWelcomeMessage selected={welcomeMessage} setSelected={setWelcomeMessage} messages={welcome} />
              )}
              {tab === 1 && <SelectInstructions messages={rule} setSelected={setRules} selected={rules} />}
              {tab === 2 && (
                <SelectFarewellMessage
                  messages={farewell}
                  selected={farewellMessage}
                  setSelected={setFarewellMessage}
                />
              )}
              {tab === 3 && (
                <SelectProperty
                  usedProperties={usedProperty}
                  property={propertiesList ?? []}
                  selected={properties}
                  setSelected={setProperties}
                />
              )}
              {tab === 4 && (
                <>
                  <PreviewTemplate
                    data={{
                      id: data?.id ?? 0,
                      name: formName!,
                      welcomeMessageId: welcomeMessage!,
                      welomeMessageId: welcomeMessage!,
                      farewellMessageId: farewellMessage!,
                      ruleMessages: rule.filter((r) => rules.includes(r.id)),
                      property: properties ?? [],
                      propertyIds: properties.map((p) => p.propertyId) ?? [],
                    }}
                    welcome={welcome}
                  />

                  <TemplateMobileView
                    open={mobileOpen}
                    handleClose={() => setMobileOpen(false)}
                    data={{
                      id: data?.id ?? 0,
                      name: formName!,
                      welcomeMessageId: welcomeMessage!,
                      welomeMessageId: welcomeMessage!,
                      farewellMessageId: farewellMessage!,
                      ruleMessages: rule.filter((r) => rules.includes(r.id)),
                      property: properties ?? [],
                      propertyIds: properties.map((p) => p.propertyId) ?? [],
                    }}
                    welcome={welcome}
                  />
                </>
              )}
              <Box sx={{ px: 1 }}>
                {tab < 3 && (
                  <Button
                    size={'large'}
                    disabled={!validation[tab + 1]}
                    sx={{ mt: 2 }}
                    variant={'contained'}
                    onClick={() => setTab(tab + 1)}
                    fullWidth
                  >
                    {t('GENERAL.NEXT')}
                  </Button>
                )}
                {tab == 3 && (
                  <Button
                    size={'large'}
                    disabled={!properties.length}
                    sx={{ mt: 2 }}
                    variant={'contained'}
                    onClick={() => setTab(4)}
                    fullWidth
                  >
                    {t('GENERAL.EXECUTE')}
                  </Button>
                )}
                {tab == 4 && (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      size={'large'}
                      disabled={mutating > 0}
                      sx={{ mt: 2 }}
                      variant={'contained'}
                      onClick={() => handleSend()}
                      fullWidth
                    >
                      {t('GENERAL.EXECUTE')}
                    </Button>
                    <Button
                      size={'large'}
                      sx={{ mt: 2 }}
                      variant={'outlined'}
                      onClick={() => setMobileOpen(true)}
                      fullWidth
                    >
                      {t('GENERAL.PREVIEW')}
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Box sx={{ px: 1 }}>
              <Typography mb={1}>{t('MESSAGING.FORM_NAME')}</Typography>
              <TextField
                onChange={(event) => setFormName(event.target.value)}
                name='formName'
                sx={{
                  '.MuiInputBase-root': {
                    borderRadius: '24px',
                  },
                  form: {
                    width: '100%',
                  },
                  '.MuiFormHelperText-root': {
                    textAlign: 'start',
                  },
                }}
                value={formName}
                placeholder={t('MESSAGING.FORM_NAME')}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <Button
                size={'large'}
                disabled={!formName?.length}
                sx={{ mt: 2 }}
                variant={'contained'}
                onClick={() => setStarted(true)}
                fullWidth
              >
                {t('MESSAGING.START')}
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AddMessageModal;
