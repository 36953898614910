// @ts-nocheck
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'

export const getCellPrice = (date, searchable = [], propertyName) => {
	let price = 0
	let airbnbPrice = 0
	let gathernPrice = 0
	let bookingPrice = 0
	searchable?.filter(x => x?.propertyName == propertyName).map(c => {
		c?.dates?.map(current => {

			if (format(date, "dd-MM-yyyy") == format(current?.date, "dd-MM-yyyy")) {
				price = current?.gathren_daily_price || current?.airbnb_daily_price
				gathernPrice = current?.gathren_daily_price
				airbnbPrice = current?.airbnb_daily_price


				return
			}
		})

	})



	return {
		price,
		airbnbPrice,
		gathernPrice,
		bookingPrice
	}

}
export const generatePriceSelection = (selections, searchable = [], propertyName) => {
	let fri = 0
	let thu = 0
	let sat = 0
	let mid = 0
	let rate = 0
	searchable?.filter(x => x?.propertyName == propertyName).map(c => {
		c?.dates?.map(current => {

			if (selections?.includes(format(current?.date, "dd-MM-yyyy"))) {
				let dayName = format(current?.date, 'iiii', { locale: enUS })
				let gathernPrice = current?.gathren_daily_price
				let airbnbPrice = current?.airbnb_daily_price
				if (airbnbPrice) {
					rate = airbnbPrice
				}
				if (gathernPrice) {
					if (dayName == 'Friday') {
						fri = gathernPrice
					}
					if (dayName == 'Saturday') {
						sat = gathernPrice
					}
					if (dayName == 'Thursday') {
						thu = gathernPrice
					}
					else {
						mid = gathernPrice

					}
				}
			}
		})

	})
	return {
		fri,
		thu,
		sat,
		mid,
		rate
	}
}

export const generateDaysFormat = (selection, date) => {
	
	if (selection.length == 0) return []
	let result = []
	if (selection.length > 1) {
		
		var cur =selection[0]
	
		while (cur < selection[selection.length-1]) {
			let d = new Date(date)
			d.setDate(cur+1)
			result.push(format(d, "dd-MM-yyyy"))
			cur++
		}
	} else {
		let d = new Date(date)
		d.setDate(selection[0])
		result = [format(d, "dd-MM-yyyy")]
	}

	return result
}