import { useTranslation, getI18n } from 'react-i18next';

export const DaysShortCut = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat'
  ]
  export const DaysShortCutAr = [
	'الأحد',
	'الاثنين',
	'الثلاثاء',
	'الأربعاء',
	'الخميس',
	'الجمعة',
	'السبت'
  ]
  export const generateWeak = (start:any) => {
	const t = getI18n()
	let result = []
	let data = [...DaysShortCut]
	let currentDayIndx = DaysShortCut.findIndex(c => c == start)
	data = data.filter(w => w !== start)
	result[0] = start
	if (currentDayIndx == 0) {
	  data.map(c => result.push(c))
	} else {
	  // greater than zero
	  //days from indx to end
	  data.filter((x, i) => i >= currentDayIndx).map(c => result.push(c))
	  data.filter((x, i) => i < currentDayIndx).map(c => result.push(c))
  
	}
	if (t.language == 'ar') {
	  result = result.map(c => {
		const enIndx = DaysShortCut.findIndex(cc => cc == c)
		return DaysShortCutAr[enIndx]
	  })
	}
  
	return result
  }