import { Grid } from "@mui/material"
// import CalendarModal from "./components/CalendarModal"
import Filters from "./components/Custom/filters"
import { useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import AdvancedSettings from "./components/advancedSettings"
import { useRecoilValue } from "recoil"
import {
	controleAdvancedSettings,
	controleBookingDetails,
	gitBookingInfo,
	isMonthlyView,
} from "@store/index"
import WeeklyCalendarModal from "./components/WeeklyCalendarModal"
import BookingDetails from "./components/BookingDetails "

export default function CalendarComponents() {
	const calendarRef = useRef<FullCalendar | null>(null)
	const controleAdvancedSettingsValue = useRecoilValue(controleAdvancedSettings)
	const controleBookingDetailsValue = useRecoilValue(controleBookingDetails)
	const gitBookingInfoState = useRecoilValue(gitBookingInfo)
	const isMonthly = useRecoilValue(isMonthlyView)
	const [isChangeView, setIsChangeView] = useState(false)

	useEffect(() => {
		if (
			controleBookingDetailsValue &&
			gitBookingInfoState &&
			!controleAdvancedSettingsValue
		) {
			setIsChangeView(true)
		}
		if (
			!controleBookingDetailsValue &&
			gitBookingInfoState === undefined &&
			controleAdvancedSettingsValue
		) {
			setIsChangeView(true)
		}
		if (!controleBookingDetailsValue && !controleAdvancedSettingsValue) {
			setIsChangeView(false)
		}
	}, [
		controleBookingDetailsValue,
		gitBookingInfoState,
		controleAdvancedSettingsValue,
		setIsChangeView,
	])
	return (
		<Grid container>
			<Grid item xs={12}>
				<Filters calendarRef={calendarRef} />
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={"16px"}>
					<Grid item xs={isChangeView ? 8 : 12}>
						{/* {isMonthly === "0" ? (
							<CalendarModal calendarRef={calendarRef} />
						) : (
						)} */}
						<WeeklyCalendarModal />
					</Grid>
					{controleAdvancedSettingsValue && (
						<Grid item xs={4}>
							<AdvancedSettings />
						</Grid>
					)}
					{controleBookingDetailsValue && (
						<Grid item xs={4}>
							<BookingDetails />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}
