export function GetDateRangeBetweenTwoDates(startDate:Date, endDate:Date, steps = 1) {
	const dateArray = [];
	let currentDate = new Date(startDate);
  
	while (currentDate <= new Date(endDate)) {
	  dateArray.push(new Date(currentDate));
	  // Use UTC date to prevent problems with time zones and DST
	  currentDate.setUTCDate(currentDate.getUTCDate() + steps);
	}
  
	return dateArray?.splice(0, dateArray.length - 1)
  }