// @ts-nocheck
import { format, setDefaultOptions, differenceInDays } from 'date-fns';

export const getBookedDates = (start = new Date, end = new Date) => {
	for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
		arr.push(new Date(dt));
	}
	return arr;

}
export const getSameBookingDateDictionary = (dateRange:any, propertyName='', list:any) => {
	let result = {
	}
	list?.filter(x => x?.propertyName == propertyName).map(crr => {
		crr?.dates?.map(days => {
			days.days
				.map(c1 => {
					dateRange?.map(c2 => {
						if (c2 == format(c1, "dd-MM-yyyy")) {
							result[format(c1, "dd-MM-yyyy")] = result[format(c1, "dd-MM-yyyy")] ? [...result[format(c1, "dd-MM-yyyy")], days?.reservation_id] : result[format(c1, "dd-MM-yyyy")] = [days?.reservation_id]
						}
					})
				})

		})

	})
	return result

}

export const GetWeakFromTwoDates = (start = new Date()): Date[] => {
	let result: Date[] = []
	Array.from({ length: 7 }).map((_, i) => {
		let end = new Date(start);
		end.setDate(end.getDate() + i);
		result.push(end)
	})
	return result
}