import styled from "styled-components"

export const FiltersWrapper = styled.div`
	margin-bottom: 24px;
	.containerInfo {
		p {
			margin: 0;
			&:first-child {
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				color: #130080;
			}
			&:last-child {
			}
		}
	}

	.containerControls {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		.containerSelectOption {
			> div {
				display: inline-block;
				background-color: #fff;
				padding: 14px 30px;
				border-radius: 20px;
				> div {
					padding: 0;
					> div {
						padding: 0;
					}
				}
			}
		}
		.containerButton {
			button {
				border: none;
				display: inline-block;
				background-color: #fff;
				padding: 15px 24px;
				border-radius: 20px;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				color: #261b6a;
				font-weight: 600;
				cursor: pointer;
			}
		}
		.active {
			button {
				cursor: pointer;
				border: none;
				display: inline-block;
				padding: 15px 24px;
				border-radius: 20px;
				font-size: 14px;
				font-weight: 300;
				line-height: 16px;
				color: #fff;
				font-weight: 600;
				background-color: #5b3fff;
			}
		}
	}
`
