import { Box, Slide, Typography, Collapse } from "@mui/material"
import { LockDetails } from "@pages"
import Copy from "@assets/svg/copy.svg?react"
import MoreIcon from "@assets/svg/more.svg?react"
import {
    useState,
    useContext
} from 'react'
import { ToastMessageContext } from "@context"

import {

	DeleteLockModal,
    CreateEmergencyLockModal
} from "@components"
import { t } from "i18next"
import { useLock } from "@services"

const BookingInfoLockDetails = ({
    id='',
    onCopyLockId=()=>{},
    propertyName=''
}:any)=>{
    const [showControleMenue, setShowControlMenue] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [openCreateEmergencyLockModal, setOpenCreateEmergencyLockModal] =
		useState(false)

    const { getActivePassCodeQuery, disablePassCodeMutate, getLockDetailsQuery } =useLock()
    const {
		data: lockDetails,
		isLoading: isLockDetailsLoading,
		isError: lockDetailsError,
		refetch: refreshLockDetails,
	} = getLockDetailsQuery(id)

	const { showError, showSuccess } = useContext(ToastMessageContext)
    const { mutate: disablePassCode } = disablePassCodeMutate({
		onSuccess: () => {
			setShowDeleteModal(false)
			refreshLockDetails()
			showSuccess(t("LOCKS.UPDATE_SUCCESS"))
		},
		onError: () => {
			setShowDeleteModal(false)
			showError(t("ERRORS.SOMETHING_WENT_WRONG"))
		},
	})
    if(!lockDetails?.lockDetails)return null
    return (
        <Box>
        {lockDetails?.lockDetails ? (
            <Slide
                direction='up'
                style={{ transitionDelay: true ? "300ms" : "0ms" }}
                in={true}
            >
                <Box
                    className='mt-4'
                    sx={{
                        backgroundColor: "white",
                        boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.1)",
                        borderRadius: 4,
                        display: "flex",
                        p: 3,
                        pb: 0,
                        flexDirection: "column",
                        textDecoration: "none",
                        color: "inherit",
                        position: "relative",
                        width: "265px",
                    }}
                >
                    <div>
                        {lockDetails?.lockDetails?.isLockDisabled ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#FFC2C8",
                                    height: "25px",
                                    borderRadius: 10,
                                    color: "#D70015",
                                    fontSize: 13,
                                    fontWeight: 400,
                                    px: 2,
                                    mb: 1.5,
                                    minWidth: "140px",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#D70015",
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5,
                                        marginX: 0.3,
                                    }}
                                />
                                <Box
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 0.3,
                                    }}
                                >
                                    {t("NEW_LOCKS.LOCK_DISABLED")}
                                </Box>
                            </Box>
                        ) : null}

                        {lockDetails?.lockDetails?.isLockDurationEnded ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#FFF2D2",
                                    height: "25px",
                                    borderRadius: 10,
                                    color: "#FDBF21",
                                    fontSize: 11,
                                    fontWeight: 400,
                                    px: 2,
                                    mb: 1.5,
                                    minWidth: "155px",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#FDBF21",
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5,
                                        marginX: 0.3,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 0.3,
                                    }}
                                >
                                    {t("NEW_LOCKS.LOCK_DURATION_ENDED")}
                                </Box>
                            </Box>
                        ) : null}
                        {lockDetails?.lockDetails?.lockActiveTill ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#E4FCE6",
                                    height: "25px",
                                    borderRadius: 10,
                                    color: "#16AE26",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    px: 2,
                                    mb: 1.5,
                                    minWidth: "170px",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#16AE26",
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5,
                                        marginX: 0.3,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 0.3,
                                    }}
                                >
                                    {t("NEW_LOCKS.LOCK_ACTIVE_DURATION") +
                                        lockDetails?.lockDetails?.lockActiveTill}
                                </Box>
                            </Box>
                        ) : null}
                        {/* {
          lockDetails?.lockDetails?.isDoorOpen ? <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#E4FCE6', height: '25px', borderRadius: 10, color: '#16AE26', fontSize: 13, fontWeight: 400, px: 2, mb: 1.5, minWidth: '140px' }} >
            <Box sx={{ backgroundColor: '#16AE26', width: 10, height: 10, borderRadius: 5, marginX: 0.3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 0.3 }} >
              {t('NEW_LOCKS.DOOR_OPENDED')}
            </Box>
          </Box> : null
        } */}
                        {/* {
          !lockDetails?.lockDetails?.isDoorOpen ? <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFF2D2', height: '25px', maxWidth: '155px', borderRadius: 10, color: '#FDBF21', fontSize: 13, fontWeight: 400, px: 2, mb: 1.5, minWidth: '140px' }} >
            <Box sx={{ backgroundColor: '#FDBF21', width: 10, height: 10, borderRadius: 5, marginX: 0.3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 0.3 }} >
              {t('NEW_LOCKS.DOOR_CLOSED')}
            </Box>
          </Box> : null
        } */}

                        <div className='flex items-center'>
                            <LockDetails />
                            <div>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: "#261B6A",
                                        marginX: 1,
                                    }}
                                >
                                    {t("NEW_LOCKS.LOCK_DETAILS")}
                                </Typography>
                            </div>
                        </div>
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
                        >
                            <Box sx={{ fontWeight: 400, color: "#435363", fontSize: 14 }}>
                                {t("NEW_LOCKS.LOCK_CODE")}
                            </Box>
                            <Box
                                onClick={()=>{
                                    navigator.clipboard.writeText(
                                        lockDetails?.lockDetails?.lockCode
                                    )
                                    showSuccess('Copied')
                                }}
                                sx={{
                                    cursor: "pointer",
                                    color: "#130080",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    display: "flex",
                                }}
                            >
                                <Copy style={{ marginRight: "1px", marginLeft: "1px" }} />
                                <Box>{lockDetails?.lockDetails?.lockCode || "-"}</Box>
                            </Box>
                        </Box>
                    </div>

                    <Box>
                        <Box
                            sx={{
                                fontWeight: 400,
                                color: "#435363",
                                fontSize: 14,
                                marginTop: 1,
                            }}
                        >
                            {t("NEW_LOCKS.ANOTHER_INFORMATION")}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 0.7,
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    fontWeight: 400,
                                    color: "#8E98A1",
                                    fontSize: 12,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t("NEW_LOCKS.BATTERY_LEVEL")}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#F3FBF4",
                                    height: 25,
                                    maxWidth: 150,
                                    borderRadius: 10,
                                    color: "#16AE26",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    px: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#16AE26",
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5,
                                        marginX: 0.5,
                                        marginTop: -0.4,
                                    }}
                                />
                                {lockDetails?.lockDetails?.batteryLevel + "%"}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 0.7,
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    fontWeight: 400,
                                    color: "#8E98A1",
                                    fontSize: 12,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t("NEW_LOCKS.DOOR_STATUS")}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#F3FBF4",
                                    height: 25,
                                    maxWidth: 150,
                                    borderRadius: 10,
                                    color: "#16AE26",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    px: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#16AE26",
                                        width: 10,
                                        height: 10,
                                        borderRadius: 5,
                                        marginX: 0.5,
                                        marginTop: -0.4,
                                    }}
                                />
                                {lockDetails?.lockDetails?.isDoorOpen
                                    ? t("LOCKS.OPEN")
                                    : t("NEW_LOCKS.CLOSED")}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{ height: "1px", backgroundColor: "#F0F1F2", marginY: 1 }}
                    />

                    {false ? (
                        <Box>
                            <Box
                                sx={{
                                    color: "#435363",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    mt: 1,
                                }}
                            >
                                {t("NEW_LOCKS.ROOM_AVAILABLE")}
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: "#5A3FFE",
                                    width: "95%",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    height: "44px",
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    borderRadius: "20px",
                                    marginBottom: 2,
                                    marginTop: 1,
                                    cursor: "pointer",
                                }}
                            >
                                {t("NEW_LOCKS.EMERGENCY_LOCK")}
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                color: "#8E98A1",
                                fontWeight: 500,
                                fontSize: 14,
                                marginBottom: 1,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>{t("NEW_LOCKS.CONTROL_LOCK")}</Box>
                            <MoreIcon style={{ cursor: "pointer" }} onClick={()=>setShowControlMenue(true)} />
                        </Box>
                    )}

                    <Collapse in={showControleMenue}>
                        {" "}
                        <div >
                            {" "}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    bottom: -40,
                                    left: 5,
                                    width: "135px",
                                    height: "70px",
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)",
                                }}
                            >
                                <Box
                                    onBlur={() => alert("o")}
                                    onClick={() => {
                                        if (lockDetails?.lockDetails?.isLockDisabled) return
                                        setShowControlMenue(false)
                                        setShowDeleteModal(true)
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        color: "#D70015",
                                        fontSize: 14,
                                        fontWeight: 300,
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {t("NEW_LOCKS.DISABLE_LOCK")}
                                </Box>
                                <Box
                                    onClick={()=>{
                                        setOpenCreateEmergencyLockModal(true)
                                        setShowControlMenue(false)
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        backgroundColor: "#EEEBFF",
                                        fontSize: 14,
                                        fontWeight: 300,
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box>{t("NEW_LOCKS.CREATE_EMERGENCY_LOCK")}</Box>
                                </Box>
                            </Box>
                        </div>
                    </Collapse>
                </Box>
            </Slide>
        ) : null}
        <DeleteLockModal
						open={showDeleteModal || true}
						onDelete={() =>
							disablePassCode({
								id: lockDetails?.lockDetails?.lockId,
								code: lockDetails?.lockDetails?.lockCode,
							})
						}
						handleClose={() => setShowDeleteModal(false)}
					/>
                    	<CreateEmergencyLockModal
						open={openCreateEmergencyLockModal}
						handleClose={() => {
							setOpenCreateEmergencyLockModal(false)
						}}
						data={{
							lockId: lockDetails?.lockDetails?.lockId,
							propertyname: propertyName,
						}}
					/>
        </Box>
    )
}
export default BookingInfoLockDetails

