import { Grid } from "@mui/material"
import { PopupConfirmationWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { Dispatch, SetStateAction } from "react"
import { useNavigate } from "react-router-dom"

interface IProps {
	setConfirmationMessage: Dispatch<SetStateAction<boolean>>
	onSubmitForm: () => Promise<void>
	isActiveStep: number
}
export default function PopupConfirmation({
	setConfirmationMessage,
	onSubmitForm,
	isActiveStep,
}: IProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<PopupConfirmationWrapper>
			<Grid container justifyContent={"center"}>
				<Grid item xs={12} md={4}>
					<div className='containerCard'>
						<div className='header'>
							<p>{t("MANUALRESERVATION.CANCELLATIONOFRESERVATION")}</p>
						</div>
						<div className='body'>
							<p>
								{t(
									"MANUALRESERVATION.AREYOUSUREYOUWANTTOCANCELYOURRESERVATION"
								)}
							</p>
						</div>
						<div className='footer'>
							<button onClick={() => setConfirmationMessage(false)}>
								{t("CANCEL")}
							</button>
							<button
								onClick={() => {
									isActiveStep === 4 ? onSubmitForm() : navigate("/calendar")
								}}
							>
								{t("MANUALRESERVATION.CANCELLATIONOFRESERVATION")}
							</button>
						</div>
					</div>
				</Grid>
			</Grid>
		</PopupConfirmationWrapper>
	)
}
