import Input from "@Common/input"
import { FormProvider, useForm } from "react-hook-form"
import { SearchInputFormWrapper } from "./styles"
import { useTranslation } from "react-i18next"
interface IForm {
	search: string
}
export default function SearchInputForm() {
	const { t } = useTranslation()
	const methods = useForm<IForm>()
	const onSubmit = async (value: IForm) => {
	}
	return (
		<SearchInputFormWrapper>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Input
						name='search'
						label=''
						placeholder={t("COMMON.SEARCH_PROPERY")}
						type='search'
						withOutErrorMessage
					/>
				</form>
			</FormProvider>
		</SearchInputFormWrapper>
	)
}
